import { Dialog } from "@mui/material";
import QISignTheme from "../../../interfaces/Theme";
import * as S from './styles';
import darkOrLightMode from "../../../utils/helpers/colorMode";
import { ThemedButton } from "../../../components/ThemedButton";
import { Cross2Icon } from "@radix-ui/react-icons";

export const NotFoundDialog = ({
    open,
    onClose,
    clientThemeSettings
} : {
    open: boolean,
    onClose: () => void,
    clientThemeSettings: QISignTheme
}) => {


    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose} 
            keepMounted={false}
        >
            <S.Container>
                <S.Header $backgroundColor={clientThemeSettings.background_color}>
                    <S.Title $colorMode={darkOrLightMode(clientThemeSettings.background_color)}>
                        Erro
                    </S.Title>
                    <S.IconFrame onClick={onClose}>
                        <Cross2Icon width={24} height={24} color={darkOrLightMode(clientThemeSettings.background_color) === 'light mode' ? '#232323' : '#fff'}/>
                    </S.IconFrame>
                </S.Header>
                <S.DescriptionContainer>
                    <S.Description>Os dados recebidos estão incorretos.</S.Description>
                </S.DescriptionContainer>
                <S.ButtonContainer>
                    <ThemedButton
                        clientThemeSettings={clientThemeSettings}
                        loading={false}
                        onClick={onClose}
                    >
                        Continuar
                    </ThemedButton>
                </S.ButtonContainer>
            </S.Container>
        </Dialog>
    )
}