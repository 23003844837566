import styled, { DefaultTheme } from 'styled-components';
import { ButtonBase } from '@mui/material';

import { getColorContrast } from '../../utils/helpers'

type ButtonType = {
  type: 'clientType' | 'primary' | 'primaryGhost' | 'secondary' | 'secondaryGhost';
  disabled: boolean;
  $clientThemeSettings: ClientThemeSettings;
  $isLoading: boolean;
};

const buttonStyle = (theme: DefaultTheme, customColor = '') => ({
  primary: {
    color: getColorContrast(customColor) || theme.colors.colorNeutral,
    backgroundColor: customColor || theme.colors.colorPrimary,
    border: '0',
    leftIconColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    rightIconColor: getColorContrast(customColor) || theme.colors.colorNeutral,
  },
  primaryGhost: {
    color: customColor || theme.colors.colorPrimary,
    backgroundColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    border: `1px solid ${customColor || theme.colors.colorPrimary}`,
    leftIconColor: customColor || theme.colors.colorPrimary,
    rightIconColor: customColor || theme.colors.colorPrimary,
  },
  secondary: {
    color:  getColorContrast(customColor) || theme.colors.colorNeutral,
    backgroundColor: customColor || theme.colors.colorSecondary,
    border: '0',
    leftIconColor: customColor || theme.colors.colorSecondary,
    rightIconColor: customColor || theme.colors.colorSecondary,
  },
  secondaryGhost: {
    color: customColor || theme.colors.colorSecondary,
    backgroundColor: getColorContrast(customColor) || theme.colors.colorNeutral,
    border: `1px solid ${customColor || theme.colors.colorSecondary}`,
    leftIconColor: customColor || theme.colors.colorSecondary,
    rightIconColor: customColor || theme.colors.colorSecondary,
  },
})

export const ButtonWrapper = styled(ButtonBase)<ButtonType>`
  width: 100% !important;
  min-width: 140px;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  opacity: ${props => props.$isLoading ? 1 : props.disabled ? 0.4 : 1} !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  cursor: ${props => props.disabled ? 'not-allowed' : "pointer"} !important;
  /* Reset Material UI default styles */
  background-image: none !important;
  box-shadow: none !important;
  transition: none !important;
  /* Ensure override of any Material UI styles */
  &&& {
    ${(props) => {
      const getClientTheme = props?.$clientThemeSettings?.button_color || '';
      const defaultButtonStyle = buttonStyle(props.theme, getClientTheme)[props.type];
      
      return `
        color: ${defaultButtonStyle.color} !important;
        background-color: ${defaultButtonStyle.backgroundColor} !important;
        border: ${defaultButtonStyle.border} !important;
        &:hover {
          background-color: ${defaultButtonStyle.backgroundColor}CC !important;
        }
      `
    }}
  }
`;

export const LoadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;