import * as S from './styles'
import * as G from '../../theme/global-styles'
import ClientLogo from "../../components/ClientLogo";
import PoweredByQITech from "../../components/PoweredByQITech";
import { CrossCircledIcon } from "@radix-ui/react-icons";

export const CanceledPage = (props) => {

    const { clientThemeSettings } = props

    return (
        <G.Container>
            <S.Header>
                <ClientLogo clientThemeSettings={clientThemeSettings} size='small' />
            </S.Header>
            <S.ExpiredContainer>
                <CrossCircledIcon color="red" width={32} height={32} />
                <G.Title>Processo de assinatura cancelado</G.Title>
            </S.ExpiredContainer>
            <S.Footer>
                <PoweredByQITech />
            </S.Footer>
        </G.Container>
    )
}