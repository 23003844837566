import * as S from './styles'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { LoadingPage } from '../LoadingPage'
import { ErrorPage } from '../ErrorPage'
import FormField from '../../components/FormField'
import * as G from '../../theme/global-styles'
import ClientLogo from '../../components/ClientLogo'
import PoweredByQITech from '../../components/PoweredByQITech'
import { ThemedButton } from '../../components/ThemedButton'
import darkOrLightMode from '../../utils/helpers/colorMode'


export default function EnrichPersonalData({ setSignerStep, clientThemeSettings, signData, setSignData, refreshSignData, appTheme }: {
    setSignerStep: (step: string) => void,
    clientThemeSettings: any,
    signData: any,
    setSignData: (data: any) => void,
    refreshSignData: () => Promise<any>,
    appTheme: string | undefined
}) {

    const [focusedOnce, setFocusedOnce] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<'account' | 'pix_dict'>('account');
    const [pixDictType, setPixDictType] = useState<'cpf' | 'phone' | 'email' | 'random_key'>('cpf');
    const [pixDict, setPixDict] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean | any>(false)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]((?!\.\.)\.([a-zA-Z]{2,}))+$/;
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    const [currentForm, setCurrentForm] = useState<'personal_data' | 'address' | 'payment_data'>('personal_data');

    const handleFormIncrement = () => {
        if (currentForm === 'personal_data') {
            setCurrentForm('address');
        } else if (currentForm === 'address') {
            setCurrentForm('payment_data');
        }
    }

    useEffect(() => {
        if (signData.forced_payment_method === 'pix_document_number') {
            setPaymentMethod('pix_dict');
        }
    }, [signData]);

    const handleFormUpdate = (form: 'personal_data' | 'address' | 'payment_data') => {
        if (currentForm === 'address') {
            if (form === 'personal_data') {
                setCurrentForm('personal_data');
            }
        }
        if (currentForm === 'payment_data') {
            if (form === 'address') {
                setCurrentForm('address');
            } else if (form === 'personal_data') {
                setCurrentForm('personal_data');
            }
        }
    }

    const validateSubforms = () => {
        if (currentForm === 'personal_data') {
            if (!emailRegex.test(email)) return false;
            if (phone.length < 14 || phone.length > 15) return false;
            return true;
        } else if (currentForm === 'payment_data') {
            if (paymentMethod === 'pix_dict') {
                if (pixDictType === 'phone') {
                    if (pixDict.length < 14 || pixDict.length > 15) return false;
                } else if (pixDictType === 'email') {
                    if (!emailRegex.test(pixDict)) return false;
                } else if (pixDictType === 'random_key') {
                    if (!uuidRegex.test(pixDict)) return false;
                }
                return true
            }
            if (paymentMethod === 'account') {
                if (bankCode.length !== 3) return false;
                if (account.length !== 7) return false;
                if (agency.length !== 4) return false;
                if (accountDigit.length !== 1) return false;
                return true;
            }
        } else if (currentForm === 'address') {
            if (address.street.length === 0) return false;
            if (address.number.length === 0) return false;
            if (address.neighborhood.length === 0) return false;
            if (address.city.length === 0) return false;
            if (address.uf.length === 0) return false;
            if (address.postal_code.length === 0) return false;
            return true;
        }
    }

    const [address, setAddress] = useState<any>({
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        uf: 'SP',
        postal_code: '',
    });

    const [bankCode, setBankCode] = useState<string>('');
    const [agency, setAgency] = useState<string>('');
    const [account, setAccount] = useState<string>('');
    const [accountDigit, setAccountDigit] = useState<string>('');

    const [formValid, setFormValid] = useState(false);
    const [subformValid, setSubformValid] = useState(false);

    const formatPhoneNumber = (value: string): string => {
        if (value) {
            const digits = value.replace(/\D/g, '');
            if (digits.length <= 10) {
                // Format as (XX) XXXX-XXXX (landline)
                return digits
                    .replace(/(\d{2})(\d)/, '($1) $2')
                    .replace(/(\d{4})(\d)/, '$1-$2')
                    .slice(0, 14);
            } else {
                // Format as (XX) XXXXX-XXXX (mobile)
                return digits
                    .replace(/(\d{2})(\d)/, '($1) $2')
                    .replace(/(\d{5})(\d)/, '$1-$2')
                    .slice(0, 15);
            }
        }
        return '';
    }

    const formatCEP = (value: string): string => {
        if (value) {
            const digits = value.replace(/\D/g, '');
            return digits
                .replace(/(\d{5})(\d)/, '$1-$2')
                .slice(0, 9);
        }
        return '';
    }

    const validate = () => {
        if (!emailRegex.test(email)) return false;
        if (phone.length < 14 || phone.length > 15) return false;
        if (paymentMethod === 'pix_dict') {
            if (pixDictType === 'phone') {
                if (pixDict.length < 14 || pixDict.length > 15) return false;
            } else if (pixDictType === 'email') {
                if (!emailRegex.test(pixDict)) return false;
            } else if (pixDictType === 'random_key') {
                if (!uuidRegex.test(pixDict)) return false;
            }
        }
        if (paymentMethod === 'account') {
            if (bankCode.length !== 3) return false;
            if (account.length !== 7) return false;
            if (agency.length !== 4) return false;
            if (accountDigit.length !== 1) return false;
        }
        if (address.street.length === 0) return false;
        if (address.number.length === 0) return false;
        if (address.neighborhood.length === 0) return false;
        if (address.city.length === 0) return false;
        if (address.uf.length === 0) return false;
        if (address.postal_code.length === 0) return false;
        return true;
    }

    useEffect(() => {
        const valid = validate();
        setFormValid(valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, phone, paymentMethod, pixDict, pixDictType, address, bankCode, agency, account, accountDigit]);

    useEffect(() => {
        const valid = validateSubforms();
        setSubformValid(valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm, email, phone, paymentMethod, pixDict, pixDictType, address, bankCode, agency, account, accountDigit]);


    const handleContinue = async () => {

        if (hasMadeRequest) {
            return  
        }

        const formattedPixDict = pixDictType === 'phone' ? (pixDict || '').replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '') : pixDict;

        const mutablePaymentData = paymentMethod === 'account' ? {
            account_data: {
                bank_code: bankCode,
                branch_number: agency,
                account_number: account,
                account_digit: accountDigit,
            }
        } : {
            pix_key: pixDictType === 'cpf' ? accountHolder : formattedPixDict,
        }

        const formattedPhone = (phone || '').replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')

        const payload = {
            event_date: new Date().toISOString(),
            step: 'enrich_personal_data',
            phone: {
                international_dial_code: '55',
                area_code: formattedPhone.slice(0, 2),
                number: formattedPhone.slice(2),
            },
            email: email,
            ...mutablePaymentData,
            address: {
                street: address.street.trim(),
                number: address.number.trim(),
                complement: address.complement ? address.complement.trim() : undefined,
                neighborhood: address.neighborhood.trim(),
                city: address.city.trim(),
                uf: address.uf.trim(),
                postal_code: address.postal_code.trim(),
            }
        }
        
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }

        try {
            setLoading(true)
            setHasMadeRequest(true)
            const response = await axios.patch('/signer', payload, config);
            try {
                const refreshedSignData = await refreshSignData()
                setSignData({ ...signData, ...refreshedSignData, ...response.data})
                setSignerStep(response.data.next_step);
            } catch {
                setError(true)
            }
            
        } catch (error) {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": ((error.response || {}).status || 500)
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }
            setHasMadeRequest(false)
        }

        setLoading(false);

    }

    if (!clientThemeSettings || !signData) {
        return <></>
    }

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    if (error) {
        return (
            <ErrorPage
                error={error}
                redirectToLogin={() => setSignerStep("login")}
                resetError={() => setError({ has_error: false, status: 0 })}
                clientThemeSettings={clientThemeSettings}
            />
        )
    }

    const accountHolder = signData.signer_data.document_number;

    return (
        <G.Container style={{ padding: '24px 0' }}>
            <ClientLogo clientThemeSettings={clientThemeSettings} size="small" />
            <S.FormContainer>
                <G.Title>Insira seus dados</G.Title>
                <S.Text>
                    Preencha os campos abaixo e depois clique em "continuar":
                </S.Text>
                <S.Divider />
                <S.ProgressIndicator>
                    <S.ProgressStep>
                        <S.ProgressStepNumber onClick={() => handleFormUpdate('personal_data')} active={currentForm === 'personal_data'} clientTheme={clientThemeSettings} fontColorMode={darkOrLightMode(clientThemeSettings?.button_color)}>1</S.ProgressStepNumber>
                        <S.ProgressStepLabel>Contato</S.ProgressStepLabel>   
                    </S.ProgressStep>
                    <S.ProgressStep>
                        <S.ProgressStepNumber onClick={() => handleFormUpdate('address')} active={currentForm === 'address'} clientTheme={clientThemeSettings} fontColorMode={darkOrLightMode(clientThemeSettings?.button_color)}>2</S.ProgressStepNumber>
                        <S.ProgressStepLabel>Endereço</S.ProgressStepLabel>
                    </S.ProgressStep>
                    <S.ProgressStep>
                        <S.ProgressStepNumber onClick={() => handleFormUpdate('payment_data')} active={currentForm === 'payment_data'} clientTheme={clientThemeSettings} fontColorMode={darkOrLightMode(clientThemeSettings?.button_color)}>3</S.ProgressStepNumber>
                        <S.ProgressStepLabel>Pagamento</S.ProgressStepLabel>
                    </S.ProgressStep>
                </S.ProgressIndicator>
                <S.Divider />
                {currentForm === 'personal_data' &&
                    <>
                        <S.Subtitle>Informações de contato</S.Subtitle>
                        <S.InputContainer>
                        <FormField
                            hasError={focusedOnce.phone && (phone.length < 14 || phone.length > 15)}
                            labelValue="Telefone"
                            fieldValue={formatPhoneNumber(phone)}
                            onChange={(e) => {
                                setFocusedOnce({ ...focusedOnce, phone: true })
                                setPhone(formatPhoneNumber(e.target.value).trim())
                            }}
                            errorMessage=""
                            type="numeric"
                        />
                        <FormField
                            hasError={focusedOnce.email && !emailRegex.test(email)}
                            labelValue="Email"
                            fieldValue={email}
                            onChange={(e) => {
                                setFocusedOnce({ ...focusedOnce, email: true })
                                setEmail(e.target.value.toLowerCase().trim())
                            }}
                            errorMessage=""
                            type="email"
                        />
                        </S.InputContainer>
                    </>
                }
                {currentForm === 'address' &&
                    <>
                        <S.Subtitle>Informações de endereço</S.Subtitle>
                        <S.InputContainer>
                            <FormField
                                hasError={focusedOnce.addressPostalCode && address.postal_code.length === 0}
                                labelValue="CEP"
                                fieldValue={address.postal_code}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressPostalCode: true })
                                    setAddress({ ...address, postal_code: formatCEP(e.target.value) })
                                }}
                                errorMessage=""
                                type="numeric"
                            />
                            <FormField
                                hasError={focusedOnce.addressCity && address.city.length === 0}
                                labelValue="Cidade"
                                fieldValue={address.city}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressCity: true })
                                    setAddress({ ...address, city: e.target.value })
                                }}
                                errorMessage=""
                                type="text"
                            />
                            <div style={{ color: "#7f8fa4", fontSize: "12px", fontWeight: "400", marginBottom: "-8px" }}>Estado</div>
                            
                            <S.StyledSelect value={address.uf} onChange={(event) => {
                                setAddress({ ...address, uf: event.target.value })
                            }}>
                                <option value="SP">São Paulo (SP)</option>
                                <option value="AC">Acre (AC)</option>
                                <option value="AL">Alagoas (AL)</option>
                                <option value="AM">Amazonas (AM)</option>
                                <option value="AP">Amapá (AP)</option>
                                <option value="BA">Bahia (BA)</option>
                                <option value="CE">Ceará (CE)</option>
                                <option value="DF">Distrito Federal (DF)</option>
                                <option value="ES">Espírito Santo (ES)</option>
                                <option value="GO">Goiás (GO)</option>
                                <option value="MA">Maranhão (MA)</option>
                                <option value="MG">Minas Gerais (MG)</option>
                                <option value="MS">Mato Grosso do Sul (MS)</option>
                                <option value="MT">Mato Grosso (MT)</option>
                                <option value="PA">Pará (PA)</option>
                                <option value="PB">Paraíba (PB)</option>
                                <option value="PE">Pernambuco (PE)</option>
                                <option value="PI">Piauí (PI)</option>
                                <option value="PR">Paraná (PR)</option>
                                <option value="RJ">Rio de Janeiro (RJ)</option>
                                <option value="RN">Rio Grande do Norte (RN)</option>
                                <option value="RO">Rondônia (RO)</option>
                                <option value="RR">Roraima (RR)</option>
                                <option value="RS">Rio Grande do Sul (RS)</option>
                                <option value="SC">Santa Catarina (SC)</option>
                                <option value="SE">Sergipe (SE)</option>
                                <option value="TO">Tocantins (TO)</option>
                            </S.StyledSelect>
                            <FormField
                                hasError={focusedOnce.addressNeighborhood && address.neighborhood.length === 0}
                                labelValue="Bairro"
                                fieldValue={address.neighborhood}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressNeighborhood: true })
                                    setAddress({ ...address, neighborhood: e.target.value })
                                }}
                                errorMessage=""
                                type="text"
                            />
                            <FormField
                                hasError={focusedOnce.addressStreet && address.street.length === 0}
                                labelValue="Logradouro"
                                fieldValue={address.street}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressStreet: true }) 
                                    setAddress({ ...address, street: e.target.value })
                                }}
                                errorMessage=""
                                type="text"
                            />
                            <FormField
                                hasError={focusedOnce.addressNumber && address.number.length === 0}
                                labelValue="Número"
                                fieldValue={address.number}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressNumber: true })
                                    setAddress({ ...address, number: e.target.value })
                                }}
                                errorMessage=""
                                type="numeric"
                            />
                            <FormField
                                hasError={focusedOnce.addressComplement && address.complement.length === 0}
                                labelValue="Complemento"
                                fieldValue={address.complement}
                                onChange={(e) => {
                                    setFocusedOnce({ ...focusedOnce, addressComplement: true })
                                    setAddress({ ...address, complement: e.target.value })
                                }}
                                errorMessage=""
                                type="text"
                            />
                        </S.InputContainer>
                    </>
                }
                {currentForm === 'payment_data' &&
                    <>
                        <S.Subtitle>Informações de pagamento</S.Subtitle>
                        {signData.forced_payment_method === 'pix_document_number' ?
                            <S.Wrapper>
                                <FormField
                                    hasError={false}
                                    labelValue="Chave PIX (CPF)"
                                    fieldValue={signData.signer_data.document_number}
                                    disabled
                                    errorMessage=""
                                    readOnly
                                />
                                <S.Callout clientTheme={clientThemeSettings}>
                                    <S.CalloutTitle>Atenção</S.CalloutTitle>
                                    <S.CalloutText>
                                        Lembre-se de verificar se você tem uma chave PIX cadastrada no seu CPF.
                                    </S.CalloutText>
                                </S.Callout>
                            </S.Wrapper>
                        :
                            <>
                                <S.Selector>
                                    <S.SelectorOption selected={paymentMethod === 'account'}  onClick={() => {
                                        setPaymentMethod('account');
                                    }} clientTheme={clientThemeSettings} fontColorMode={darkOrLightMode(clientThemeSettings?.background_color)}>Conta bancária</S.SelectorOption>
                                    <S.SelectorOption selected={paymentMethod === 'pix_dict'} onClick={() => {
                                        setPaymentMethod('pix_dict');
                                    }} clientTheme={clientThemeSettings} fontColorMode={darkOrLightMode(clientThemeSettings?.background_color)}>Chave PIX</S.SelectorOption>
                                </S.Selector>
                                {paymentMethod === 'account' &&
                                    <S.InputContainer>
                                        <FormField
                                            hasError={focusedOnce.bankCode && bankCode.length !== 3}
                                            labelValue="Código do banco"
                                            fieldValue={bankCode}
                                            onChange={(e) => {
                                                setFocusedOnce({ ...focusedOnce, bankCode: true })
                                                if(e.target.value.length <= 3) {
                                                    setBankCode(e.target.value.trim())
                                                }
                                            }}
                                            errorMessage=""
                                            type="numeric"
                                        />
                                        <FormField
                                            hasError={focusedOnce.agency && agency.length !== 4}
                                            labelValue="Agência"
                                            fieldValue={agency}
                                            onChange={(e) => {
                                                setFocusedOnce({ ...focusedOnce, agency: true })
                                                if(e.target.value.length <= 4) {
                                                    setAgency(e.target.value.trim())
                                                }
                                            }}
                                            errorMessage=""
                                            type="numeric"
                                        />
                                        <FormField
                                            hasError={focusedOnce.account && account.length !== 7}
                                            labelValue="Conta"
                                            fieldValue={account}
                                            onChange={(e) => {
                                                setFocusedOnce({ ...focusedOnce, account: true })
                                                if(e.target.value.length <= 7) {
                                                    setAccount(e.target.value.trim())
                                                }
                                            }}
                                            errorMessage=""
                                            type="numeric"
                                        />
                                        <FormField
                                            hasError={focusedOnce.accountDigit && accountDigit.length !== 1}
                                            labelValue="Dígito da conta"
                                            fieldValue={accountDigit}
                                            onChange={(e) => {
                                                setFocusedOnce({ ...focusedOnce, accountDigit: true })
                                                if(e.target.value.length <= 1) {
                                                    setAccountDigit(e.target.value.trim())
                                                }
                                            }}
                                            errorMessage=""
                                            type="numeric"
                                        />
                                        <FormField
                                            hasError={false}
                                            labelValue="CPF do titular"
                                            fieldValue={accountHolder}
                                            disabled
                                            errorMessage=""
                                        />
                                    </S.InputContainer>
                
                                }
                                {paymentMethod === 'pix_dict' &&
                                    <>
                                        <S.Description><b>Tipo de chave PIX</b></S.Description>
                                        <S.RadioGroup>
                                            <S.RadioRoot>
                                                <S.Radio type='radio' checked={pixDictType === 'cpf'} onChange={() => {
                                                    setPixDictType('cpf');
                                                    setPixDict('');
                                                }} />
                                                <S.RadioLabel>CPF</S.RadioLabel>
                                            </S.RadioRoot>
                                            <S.RadioRoot>
                                                <S.Radio type='radio' checked={pixDictType === 'phone'} onChange={() => {
                                                    setPixDictType('phone');
                                                    setPixDict('');
                                                }} />
                                                <S.RadioLabel>Telefone</S.RadioLabel>
                                            </S.RadioRoot>
                                            <S.RadioRoot>
                                                <S.Radio type='radio' checked={pixDictType === 'email'} onChange={() => {
                                                    setPixDictType('email');
                                                    setPixDict('');
                                                }} />
                                                <S.RadioLabel>Email</S.RadioLabel>
                                            </S.RadioRoot>
                                            <S.RadioRoot>
                                                <S.Radio type='radio' checked={pixDictType === 'random_key'} onChange={() => {
                                                    setPixDictType('random_key');
                                                    setPixDict('');
                                                }} />
                                                <S.RadioLabel>Chave aleatória</S.RadioLabel>
                                            </S.RadioRoot>
                                        </S.RadioGroup>
                                        <S.InputContainer>
                                            {pixDictType === 'cpf' &&
                                            <>
                                                <FormField
                                                    hasError={false}
                                                    labelValue="Chave PIX"
                                                    fieldValue={signData.signer_data.document_number}
                                                    disabled
                                                    errorMessage=""
                                                />
                                                <S.Callout clientTheme={clientThemeSettings}>
                                                    <S.CalloutTitle>Atenção</S.CalloutTitle>
                                                    <S.CalloutText>
                                                        Lembre-se de verificar se você tem uma chave PIX cadastrada no seu CPF.
                                                    </S.CalloutText>
                                                </S.Callout>
                                            </>
                                            }
                                            {pixDictType === 'phone' &&
                                                <FormField
                                                    hasError={focusedOnce.pixDictPhone && (pixDict.length < 14 || pixDict.length > 15)}
                                                    labelValue="Chave PIX"
                                                    fieldValue={formatPhoneNumber(pixDict)}
                                                    onChange={(e) => {
                                                        setFocusedOnce({ ...focusedOnce, pixDictPhone: true })
                                                        setPixDict(formatPhoneNumber(e.target.value.trim()))
                                                    }}
                                                    errorMessage=""
                                                    type="numeric"
                                                />
                                            }
                                            {pixDictType === 'email' &&
                                                <FormField
                                                    hasError={focusedOnce.pixDictEmail && !emailRegex.test(pixDict)}
                                                    labelValue="Chave PIX"
                                                    fieldValue={pixDict}
                                                    onChange={(e) => {
                                                        setFocusedOnce({ ...focusedOnce, pixDictEmail: true })
                                                        setPixDict(e.target.value.toLowerCase().trim())
                                                    }}
                                                    errorMessage=""
                                                    type="email"
                                                />
                                            }
                                            {pixDictType === 'random_key' &&
                                                <FormField
                                                    hasError={focusedOnce.pixDictRandomKey && !uuidRegex.test(pixDict)}
                                                    labelValue="Chave PIX"
                                                    fieldValue={pixDict}
                                                    onChange={(e) => {
                                                        setFocusedOnce({ ...focusedOnce, pixDictRandomKey: true })
                                                        setPixDict(e.target.value.toLowerCase().trim())
                                                    }}
                                                    errorMessage=""
                                                />
                                            }
                                        </S.InputContainer>
                                    </>
                                }
                            </>
                        }
                    </>
                }
        </S.FormContainer>

            <S.Footer>
                {currentForm === 'payment_data' ?
                    <ThemedButton
                        onClick={handleContinue}
                        type='primary'
                        clientThemeSettings={clientThemeSettings}
                        $disabled={!formValid}
                    >
                        Continuar
                    </ThemedButton>
                :
                    <ThemedButton
                        onClick={() => {
                            if (subformValid) {
                                handleFormIncrement();
                            }
                        }}
                        type='primary'
                        clientThemeSettings={clientThemeSettings}
                        $disabled={!subformValid}
                    >
                        Continuar
                    </ThemedButton>
                }
                <PoweredByQITech />
            </S.Footer>
        </G.Container>
    );

};