import * as S from "./styles"
import PoweredByQITech from "../../components/PoweredByQITech"
import Theme from "../../interfaces/Theme"

export const LoadingPage = ({ clientThemeSettings }: { clientThemeSettings?: Theme }) => {

    return (
        <S.Container>
            <S.Placeholder />
            <S.Center>
                <S.Title>Carregando sua sessão</S.Title>
                <img src="/assets/icons/loading-dark.svg" alt="loading" width={32} height={32} />
            </S.Center>
            <S.Base>
                <PoweredByQITech />
            </S.Base>
        </S.Container>
    )
}