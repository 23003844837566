import './App.css'
import { MainRouter } from './container/MainRouter'
import axios from "axios"
import { ThemeProvider } from './theme'
import { CookiesProvider } from 'react-cookie'
import { DataDogService } from './services/datadog'


function App() {

  axios.defaults.baseURL = process.env.REACT_APP_SIGN_API_URL

  if (process.env.REACT_APP_ENV !== 'local') {
    DataDogService.init()
  }

  return (
    <CookiesProvider>
      <ThemeProvider>
          <MainRouter />
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default App
