import { useEffect, useState } from 'react'
import axios from 'axios'
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import * as S from './styles';
import * as G from '../../theme/global-styles';
import ClientLogo from '../../components/ClientLogo';
import PoweredByQITech from '../../components/PoweredByQITech';
import { ThemedButton } from '../../components/ThemedButton';
import { Wallpaper } from '../../components/Wallpaper';
import { Box } from '@mui/material';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import { Footer } from '../../components/Footer';
import { DataDisplayTitle, DataDisplayDescription } from '../PersonalDocument/styles';
import AdditionalContent from '../../components/AdditionalContent';

export const FraudWarning = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData, appTheme } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState({})
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)
    
    useEffect(() => {
        setLoading(false)
    }, [])
    
    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }
    
    const handleClick = () => {
        
        if (hasMadeRequest) {
            return  
        }
        
        const payload = {
            "step": "fraud_warning",
            "event_date": new Date().toISOString()
        }
        
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
        
        setLoading(true)
        setHasMadeRequest(true)
        
        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }
            
            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }
    
    const completeSignatureContent = signData.signature_settings?.complete_signature_content || {}
    const renderContent = Object.keys(completeSignatureContent || {}).length > 0;
    
    if (appTheme === 'release') {
        return (
            <G.Container style={{padding: "16px 0", alignItems: "normal"}}>
                <ClientLogo clientThemeSettings={clientThemeSettings} />
                <S.Body style={{alignItems: "normal"}}>
                    <G.Title>
                        Evite golpes!
                    </G.Title>
                    <S.Description>O cancelamento do contrato <b>{signData.company_name}</b> é realizado <b>somente</b> pela nossa <b>Central de Atendimento</b>, mediante crédito em conta de titularidade <b>{signData.company_name}</b>.</S.Description>
                    <S.Description>O processo de portabilidade é sempre realizado entre os bancos. <b>Nunca</b> pague boletos ou realize transferências (<b>PIX/TED</b>) para contas de terceiros.</S.Description>
                    <S.Description>Não aceite propostas de investimentos com o valor do empréstimo, como <b>"aluguel de margem"</b>. Trata-se de um golpe.</S.Description>
                    <S.Description><b>{signData.company_name}</b> não solicita o pagamento de qualquer garantia para a liberação do seu empréstimo.</S.Description>
                    {
                        renderContent ? 
                        <>
                            <S.Description>Caso ocorra qualquer uma das situações acima ou possuir dúvida, entre em contato com o nosso atendimento através dos seguintes meios:</S.Description>
                            <AdditionalContent content={completeSignatureContent} no_raw_test={false}/>
                        </> 
                        : <S.Description>Caso ocorra qualquer uma das situações acima, entre em contato com a nossa <b>Central de Atendimento</b>.</S.Description>              

                    }
                    <ThemedButton
                        onClick={handleClick}
                        type='primary'
                        clientThemeSettings={clientThemeSettings}
                    >
                        Estou ciente
                    </ThemedButton>
                </S.Body>
                <PoweredByQITech />
                {error.has_error &&
                    <ErrorPage
                        error={error}
                        redirectToLogin={() => setSignerStep("login")}
                        resetError={() => setError(initialError)}
                        clientThemeSettings={clientThemeSettings}
                    />
                }
            </G.Container>
        );
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box>
                    {(
                        <div>
                            <DataDisplayTitle className="title-level1">
                                Evite golpes!
                            </DataDisplayTitle>

                            <DataDisplayDescription className="description-level4" style={{marginBottom: "5px"}}>
                                <DataDisplayDescription>
                                    O cancelamento de contrato <b>{signData.company_name}</b> é realizado <b>somente</b> pela nossa 
                                    <b> Central de Atendimento</b>, mediante crédito em conta de titularidade <b>{signData.company_name}</b>.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    O processo de portabilidade é sempre realizado entre os bancos. <b>Nunca</b> pague boletos ou realize 
                                    transferências (<b>PIX/TED</b>) para contas de terceiros.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    Não aceite propostas de investimentos com o valor do empréstimo, como <b>"aluguel de margem"</b>.
                                    Trata-se de um golpe.
                                </DataDisplayDescription>
                                <DataDisplayDescription>
                                    <b>{signData.company_name} não solicita</b> o pagamento de qualquer quantia para a realização do seu contrato.
                                </DataDisplayDescription>
                                <DataDisplayDescription style={{marginBottom: "25px"}}>
                                    Caso ocorra qualquer uma das situações acima, entre em contato com a nossa <b>Central de Atendimento</b>.
                                </DataDisplayDescription >
                            </DataDisplayDescription>

                            <Button
                                onClick={handleClick}
                                type='primary'
                                clientThemeSettings={clientThemeSettings}
                            >
                                ESTOU CIENTE
                            </Button>

                        </div>
                    )}
                </Box>
                <Footer />
            </div>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
        </Wallpaper>
    )

};