function hexToRgb(hexColor: string): [number, number, number] {
    const hex = hexColor.replace(/^#/, '');
    return [
        parseInt(hex.substring(0, 2), 16),
        parseInt(hex.substring(2, 4), 16),
        parseInt(hex.substring(4, 6), 16)
    ];
}

function rgbToHex(rgb: [number, number, number]): string {
    return '#' + rgb.map(component => {
        const hex = component.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');
}

function relativeLuminance(color: [number, number, number]): number {
    const lumComponent = (comp: number): number => {
        const c = comp / 255;
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    };

    const [r, g, b] = color;
    return 0.2126 * lumComponent(r) + 0.7152 * lumComponent(g) + 0.0722 * lumComponent(b);
}

export function contrastRatio(color1: [number, number, number], color2: [number, number, number]): number {
    const lum1 = relativeLuminance(color1) + 0.05;
    const lum2 = relativeLuminance(color2) + 0.05;
    return lum1 >= lum2 ? lum1 / lum2 : lum2 / lum1;
}

export default function darkOrLightMode(hexBackgroundColor: string): 'light mode' | 'dark mode' {
    const rgbColor = hexToRgb(hexBackgroundColor);
    const blackContrast = contrastRatio(rgbColor, [0, 0, 0]);
    const whiteContrast = contrastRatio(rgbColor, [255, 255, 255]);

    return blackContrast > whiteContrast ? "light mode" : "dark mode";
}

export function changeBrightness(hexColor: string, percentage: number) {
    let delta = percentage;
    if (percentage > 100) {
        delta = 100;
    } else if (percentage < -100) {
        delta = -100;
    }

    const rgbColor = hexToRgb(hexColor);
    const outputColor: [number, number, number] = [
        Math.round(rgbColor[0] * (delta / 100)),
        Math.round(rgbColor[1] * (delta / 100)),
        Math.round(rgbColor[2] * (delta / 100))
    ];

    return rgbToHex(outputColor);

}