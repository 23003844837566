import styled from 'styled-components'

export const QRCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
