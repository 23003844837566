import React, { useCallback, useState, useEffect } from "react"
import { LoginWatcher } from "../LoginWatcher"
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { NotFoundPage } from '../NotFoundPage'
import { ExpiredPage } from '../ExpiredPage'
import { LoadingPage } from '../LoadingPage'
import { CanceledPage } from '../CanceledPage'
import { defaultThemeSettings, cpfMask, formatToYYYYMMDD } from '../../utils/utils'
import {WatcherPage} from "../WatcherPage"
import ErrorBoundaryComponent from "../ErrorBoundary"
import { useCookies } from 'react-cookie'
import { SignedPageWatcher } from "../SignedPageWatcher"
import { setDatadogPageAction } from '../../services/datadog'
export const WatcherMainContainer = (props) => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [expired, setExpired] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [clientThemeSettings, setClientThemeSettings] = useState(undefined)
    const [SignerList, setSignerList] = useState(undefined)
    const { watcherHash } = useParams()
    const [watcherStep, setwatcherStep] = useState("")
    const [documentNumberRequired, setDocumentNumberRequired] = useState(false)
    const [latitude, setLatitude] = useState(undefined)
    const [longitude, setLongitude] = useState(undefined)
    const [cookies, setCookie] = useCookies([`qisign_${watcherHash}`])

    useEffect(() => {
        setDatadogPageAction(`/w/${watcherHash} [@step=${watcherStep}]`, {
            watcherHash,
            watcherStep,
        })
    }, [watcherHash, watcherStep])

    const collectGeoLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude)
                    setLongitude(position.coords.longitude)
                }
            )
        }
    }, [])

    const submitLogin = useCallback((documentNumber, birthdate) => {
        const payload = {
            "document_number": cpfMask(documentNumber)
        }

        if (birthdate) {
            payload["birthdate"] = formatToYYYYMMDD(birthdate)
        }

        if (latitude !== undefined & longitude !== undefined) {
            payload["gps_data"] = {
                "coordinates": [
                    longitude,
                    latitude
                ]
            }
        }

        setLoading(true)
        axios.post('/watcher/hash/' + watcherHash, payload)
            .then(async (response) => {
                setCookie(`qisign_${watcherHash}`, payload, { path: `/w/${watcherHash}`, maxAge: 60 * 60 })
                setwatcherStep(response.data.watcher_data.watcher_step)
                setSignerList(response.data.watcher_data.signers_list)
            }).catch((error) => {
                setCookie(`qisign_${watcherHash}`, cookies, { path: `/w/${watcherHash}`, maxAge: 0 })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setCookie, latitude, longitude, watcherHash, setwatcherStep, cookies])
    
    useEffect(() => {
        collectGeoLocation()

        if (clientThemeSettings) return
        const defaultClientThemeSettings = {
            "logo_url": "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_azul_claro_escuro_principal.png",
            "font_color": "#000000",
            "button_color": "#4FCCED",
            "email_logo_url": "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_branco_email.png",
            "background_color": "#0E3573"
        }

        axios.get('/watcher/hash/' + watcherHash)
            .then((response) => {
                setClientThemeSettings(response.data.client_theme_settings ? response.data.client_theme_settings : defaultClientThemeSettings)
                setDocumentNumberRequired(response.data.document_number_required)
                if (['completed'].includes(response.data.envelope_status)) {
                    setwatcherStep("signed")
                } else if (response.data.envelope_status === "canceled") {
                    setCanceled(true)
                } else if (response.data.envelope_status === "expired") {
                    setExpired(true)
                }
                else {
                    setwatcherStep("login")
                }
            }).catch((error) => {
                setError(true)
            })
            .finally(() => {
                const documentNumberParamQueryParam = new URLSearchParams(window.location.search).get('document_number')
                const birthdateParamQueryParam = new URLSearchParams(window.location.search).get('birthdate')

                if (documentNumberParamQueryParam) {
                    submitLogin(documentNumberParamQueryParam, birthdateParamQueryParam)
                }
                setLoading(false)
            })
    }, [watcherHash, clientThemeSettings, submitLogin, collectGeoLocation])

    const getScenes = () => {

        if (loading) {
            return (
                <LoadingPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (error) {
            return (
                <NotFoundPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (canceled) {
            return (
                <CanceledPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (expired) {
            return (
                <ExpiredPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }

        if (watcherStep === "login") {
            return (
                <LoginWatcher
                    clientThemeSettings={clientThemeSettings}
                    documentNumberRequired={documentNumberRequired}
                    setWatcherStep={setwatcherStep}
                    setSignerList={setSignerList}
                />
            )
        } else if (watcherStep === "signed") {
            return (
                <SignedPageWatcher
                    clientThemeSettings={clientThemeSettings}
                    watcherHash={watcherHash}
                />
            )
        }
        else if (watcherStep === "on_signature") {
            return (
                <WatcherPage
                    clientThemeSettings={clientThemeSettings}
                    watcherHash={watcherHash}
                    signerList={SignerList}
                    />
            )
        } else {
            return (
                <NotFoundPage clientThemeSettings={clientThemeSettings ? clientThemeSettings : defaultThemeSettings} />
            )
        }
    }

    return (
        <ErrorBoundaryComponent clientThemeSettings={clientThemeSettings}>
            {getScenes()}
        </ErrorBoundaryComponent>
    )
}