import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Wallpaper } from '../../components/Wallpaper'
import * as S from './styles'
import { setDatadogPageAction } from '../../services/datadog'

export const RedirectContainer = () => {
    const { signerHash } = useParams()
    const [data, setData] = useState(null);
    
    useEffect(() => {
        setDatadogPageAction(`/r/${signerHash}`, {
            signerHash,
        })
    }, [signerHash])

    useEffect(() => {
        getThemeByHash(signerHash).then((response) => {
            setData(response)
        })
    }, [signerHash])

    useEffect(() => {
        if (data) {
            console.log("Data: ", data)
        }
    }, [data])

    if (!data) {
        return (
            <Wallpaper backgroundColor="#0E3573">
                <S.Card className="base-container-width base-container-padding">
                    <S.Text>
                        <b>Carregando Informações...</b>
                    </S.Text>
                </S.Card>
            </Wallpaper>
        )
    }

    return (
        <Wallpaper backgroundColor={data?.default?.background_color}>
            <S.Card className="base-container-width base-container-padding">
                <S.Header>
                    <S.Logo src={data?.default?.logo_url} />
                    <S.UpdateIcon />
                    <S.Logo src={data?.client?.logo_url} style={{ marginTop: '8px' }} />
                </S.Header>
                <S.Content>
                    <S.Text>
                        Olá <b>{data?.signer_name}</b>,
                    </S.Text>
                    <S.Text>
                        Para seguir com o empréstimo da carteira de trabalho digital, você será redirecionado para a tela do nosso parceiro, <b>{data?.client_name}</b>.
                        Clique em "Continuar" para seguir.
                    </S.Text>
                    <S.Text style={{textAlign: 'left'}}>
                        
                    </S.Text>
                </S.Content>
                <S.Button
                    onClick={() => { window.location.href = window.location.href.replace('/r', '/s') }}
                >
                    CONTINUAR
                </S.Button>
            </S.Card>
        </Wallpaper>
    );
}

const getThemeByHash = async (signerHash: string) => {
    const defaultTheme = {
        logo_url: "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_azul_claro_escuro_principal.png",
        font_color: "#000000",
        button_color: "#4FCCED",
        email_logo_url: "https://qisign-app-production.s3.amazonaws.com/logos/qi_tech_branco_email.png",
        background_color: "#0E3573"
    }

    try {
        const response = await axios.get('/signer/hash/' + signerHash)
        console.log("Response: ", response.data)
        return {
            client_name: response.data.signer_company_name ?? '',
            client: response.data.client_theme_settings,
            default: defaultTheme,
            signer_name: response.data.signer_data.name,
        }
    } catch (error) {
        return null
    }
}