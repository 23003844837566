import { styled } from '../../modules';
import { UpdateIcon as UpdateIconComponent } from '@radix-ui/react-icons';

export const Button = styled.div`
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
    background-color: #4FCCED;
    font-size: 13px;
    width: 100%;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
`;

export const Card = styled.div`
    margin: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #646464ab;
    box-shadow: #646464ab 0 2px 8px;
    background-color: rgb(245, 245, 242);
`;

export const Logo = styled.img`
    height: 48px;
    width: auto;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const UpdateIcon = styled(UpdateIconComponent)`
    width: 24px;
    height: 24px;
`;

export const Text = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #232323;
    text-align: justify;
    margin: 0;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px 0;
    margin-bottom: 16px;
`;
