import styled from 'styled-components';
import * as G from '../../theme/global-styles';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    gap: 16px;
    padding: 0 16px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ExpiredContainer = styled(G.Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const Description = styled(G.Description)`
    text-align: center;
`;
