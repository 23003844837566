import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;
    background-color: transparent;
`;

export const Title = styled.p`
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    letter-spacing: 0.5px;
`;
