import { styled } from '../../modules';
import { Button as ButtonComponent } from '../../components';

export const Button = styled(ButtonComponent)`
  margin-top: 1rem;
`;

export const Container = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: calc(100vh * 3/4);
  padding: 24px 16px;
  gap: 32px;
  justify-content: space-between;
  overflow-y: auto;
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 0 16px;
    flex: 1;
`;

export const Title = styled.p`
    font-size: 20px;
    margin: 0;
    font-weight: 900;
    color: #232323;
    width: 100%;
`;

export const Description = styled.p`
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #232323;
`;

export const IntroductionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

export const FormFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

export const TermsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Label = styled.label`
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    color: #232323;
`;

export const MainButton = styled.button<{$backgroundColor: string, $textColor: string, $loading: boolean}>`
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: ${props => props.$backgroundColor};
    color: ${props => props.$textColor};
    opacity: ${props => props.$loading ? 0.5 : 1};
    cursor: ${props => props.$loading ? 'not-allowed' : 'pointer'};
`;