import styled from "styled-components";

export const MainButton = styled.button<{$backgroundColor: string, $textColor: string, $loading: boolean, $disabled: boolean}>`
    width: fit-content;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    margin: 0;
    background-color: transparent;
    border: 1px solid ${props => props.$backgroundColor};
    color: ${props => props.$backgroundColor};
    opacity: ${props => (props.$loading || props.$disabled) ? 0.5 : 1};
    cursor: ${props => props.$loading ? 'not-allowed' : 'pointer'};
`;