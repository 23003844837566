import { useEffect, useState } from 'react'
import axios from 'axios'
import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import * as S from './styles';
import * as G from '../../theme/global-styles';
import ClientLogo from '../../components/ClientLogo';
import PoweredByQITech from '../../components/PoweredByQITech';
import { ThemedButton } from '../../components/ThemedButton';
import { Wallpaper } from '../../components/Wallpaper';
import { Box } from '@mui/material';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import { Footer } from '../../components/Footer';
import { DataDisplayDescription, DataDisplayTitle } from '../PersonalDocument/styles';

export const TermsOfUse = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData, appTheme } = props
    const initialError = {"has_error": false, "status": 0}

    const [error, setError] = useState({})
    const [loading, setLoading] = useState(true)
    const [hasMadeRequest, setHasMadeRequest] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }
    const handleClick = () => {

        if (hasMadeRequest) {
            return  
        }

        const payload = {
            "step": "terms_of_use",
            "event_date": new Date().toISOString()
        }
    
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }
    
        setLoading(true)
        setHasMadeRequest(true)

        axios.patch('/signer', payload, config)
        .then(response => {
            setSignerStep(response.data.next_step)
            setSignData({ ...signData, ...response.data })
        })
        .catch((error) => {
            if ([401, 403].includes((error.response || {}).status)) {
                setError({
                    "has_error": true,
                    "status": error.response.status
                })
            } else {
                setError({
                    "has_error": true,
                    "status": 500
                })
            }

            setHasMadeRequest(false)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const termVariables = {
        CITY: 'São Paulo',
        UF: 'SP',
        STREET: 'Rua Maria Carolina',
        NUMBER: '624',
        COMPLEMENT: 'Pátio Rebouças - 7º até 12º andar',
        NEIGHBORHOOD: 'Jardim Paulistano',
        ZIP_CODE: '01445-000',
        COMPANY_NAME: 'QI Sociedade de Crédito Direto S.A',
        DOCUMENT_NUMBER: '32.402.502/0001-35',
        CONTACT_INFORMATION_RAW: 'qitech@qitech.com.br',
        CONTACT_INFORMATION_TYPE: 'email',
    }

    const getUfFullName = (uf) => {
        const ufs = {
            "AC": "Acre",
            "AL": "Alagoas",
            "AP": "Amapá",
            "AM": "Amazonas",
            "BA": "Bahia",
            "CE": "Ceará",
            "DF": "Distrito Federal",
            "ES": "Espírito Santo",
            "GO": "Goiás",
            "MA": "Maranhão",
            "MT": "Mato Grosso",
            "MS": "Mato Grosso do Sul",
            "MG": "Minas Gerais",
            "PA": "Pará",
            "PB": "Paraíba",
            "PR": "Paraná",
            "PE": "Pernambuco",
            "PI": "Piauí",
            "RJ": "Rio de Janeiro",
            "RN": "Rio Grande do Norte",
            "RS": "Rio Grande do Sul",
            "RO": "Rondônia",
            "RR": "Roraima",
            "SC": "Santa Catarina",
            "SP": "São Paulo",
            "SE": "Sergipe",
            "TO": "Tocantins"
        }
        return ufs[uf] || uf
    }

    
    if (appTheme === 'release') {
        return (
            <G.Container style={{padding: "16px 0"}}>
                <ClientLogo clientThemeSettings={clientThemeSettings} size="small" />
                <S.TermContainer>
                    <G.Title>Termo de Uso e Política de Privacidade</G.Title>
                    <S.Subtitle>O que você vai encontrar aqui?</S.Subtitle>
                    <S.Description>
                        Nos Termos de Uso e Política de Privacidade do {termVariables.COMPANY_NAME}, você encontra as regras para a contratação de empréstimo, incluindo o tratamento de dados pessoais, pelos canais digitais de atendimento disponibilizados pelo {termVariables.COMPANY_NAME}.
                    </S.Description>
                    <S.Description>
                        Neste documento, estão as regras para o uso das plataformas, os documentos necessários para a contratação do empréstimo e suas responsabilidades durante o relacionamento com o {termVariables.COMPANY_NAME}.
                    </S.Description>
                    <S.Description>
                        <b>Lembre-se:</b> este documento pode ser atualizado periodicamente, sem a necessidade de aviso prévio, para garantir ainda mais segurança para você.     
                    </S.Description>
                    <S.Subtitle>Índice</S.Subtitle>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem>
                                Aceite da Política
                            </S.ListItem>
                            <S.ListItem>Regras para Uso da Plataforma</S.ListItem>
                            <S.ListItem>Contratação</S.ListItem>
                            <S.ListItem>Links de Terceiros</S.ListItem>
                            <S.ListItem>Isenções de Responsabilidade</S.ListItem>
                            <S.ListItem>Sanções</S.ListItem>
                            <S.ListItem>Propriedade Intelectual</S.ListItem>
                            <S.ListItem>Duração ou Finalização do Acesso</S.ListItem>
                            <S.ListItem>Dados para Contratação</S.ListItem>
                            <S.ListItem>Sistema de Informações de Crédito do Banco Central (SCR)</S.ListItem>
                            <S.ListItem>Tratamento de Dados Pessoais</S.ListItem>
                            <S.ListItem>Finalidades para Uso de Dados Pessoais</S.ListItem>
                            <S.ListItem>Política de Cookies</S.ListItem>
                            <S.ListItem>Medidas de Segurança</S.ListItem>
                            <S.ListItem>Compartilhamento de Dados Pessoais</S.ListItem>
                            <S.ListItem>Exclusão dos Dados Pessoais</S.ListItem>
                            <S.ListItem>Seus Direitos em Relação ao Tratamento de Dados Pessoais</S.ListItem>
                            <S.ListItem>Cadastro Positivo</S.ListItem>
                            <S.ListItem>Disposições Gerais</S.ListItem>
                            <S.ListItem>Legislação e Foro</S.ListItem>
                            <S.ListItem>Canais de Atendimento</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
                    <S.Subtitle>
                        Termos de Uso e Política de Privacidade
                    </S.Subtitle>
    
                    <S.Description>
                        Por meio destes Termos de Uso e Política de Privacidade (“Política”), o {termVariables.COMPANY_NAME}, instituição financeira com sede na cidade de {termVariables.CITY}, Estado de {getUfFullName(termVariables.UF)}, na {termVariables.STREET}, {termVariables.NUMBER} {termVariables.COMPLEMENT} - {termVariables.NEIGHBORHOOD}, {termVariables.CITY} - {termVariables.UF}, CEP {termVariables.ZIP_CODE} inscrito no CNPJ sob o no {termVariables.DOCUMENT_NUMBER} (“{termVariables.COMPANY_NAME}”) dispõe sobre as regras dos canais digitais de atendimento disponibilizados pelo {termVariables.COMPANY_NAME} (Plataforma) para a contratação de empréstimo, incluindo o tratamento de dados pessoais, pelo cliente qualificado na proposta de crédito (“Você”), por meio.
                    </S.Description>
    
                    <S.Description>
                        Você compreende que, a fim de atender às suas necessidades e lhe conferir maior segurança, este documento está sujeito a contínuo aprimoramento e estará sempre disponível em nosso site. Assim, Você entende ser de sua exclusiva responsabilidade consultar esta Política de tempos em tempos, a fim de verificar suas eventuais alterações.
                    </S.Description>
    
                    <S.Subtitle>1. Aceite da Política</S.Subtitle>
    
                    <S.Description>
                        Ao dar início ao processo de contratação de empréstimo, Você deverá ler o conteúdo desta Política, e, se concordar com as condições apresentadas, manifestar sua concordância, por meio do envio de mensagem ou aceite atestando tal fato por meio da Plataforma. Você poderá revisar o consentimento para determinadas finalidades nesta Política, quando assim expressamente indicado, por meio de um de nossos Canais de Atendimento indicados no item 21 deste documento. Entretanto, ao revogar o seu consentimento, Você compreende que isto poderá restringir, suspender ou cancelar alguns dos serviços ofertados pelo {termVariables.COMPANY_NAME}. De todo modo, assim que o {termVariables.COMPANY_NAME} receber sua solicitação, a depender do caso, seus dados pessoais serão excluídos, exceto caso o seu armazenamento decorra de obrigação legal ou de obrigações contratuais assumidas por Você ainda pendentes.
                    </S.Description>
    
                    <S.Subtitle>2. Regras para Uso da Plataforma</S.Subtitle>
    
                    <S.Description>
                        Ao aceitar esta Política, Você declara:
                    </S.Description>
    
                    <S.Description><b>2.1.</b> Ser maior de 18 anos;</S.Description>
                    <S.Description><b>2.2.</b> Ser responsável por quaisquer consequências relacionadas à celebração desta contratação, respondendo, inclusive perante terceiros, por qualquer reivindicação que venha a ser apresentada ao {termVariables.COMPANY_NAME} judicial ou extrajudicialmente;</S.Description>
                    <S.Description><b>2.3.</b> Ser o único responsável pelas operações financeiras que realizar, assumindo todos os riscos a estas relacionados, inclusive em caso de eventual prejuízo;</S.Description>
                    <S.Description><b>2.4.</b> Dispor de todos os recursos e infraestrutura para acesso online da Plataforma, inclusive para a manutenção da segurança de suas informações, mediante atualização dos sistemas operacionais e utilização de medidas técnicas de segurança, os quais serão de sua exclusiva responsabilidade;</S.Description>
                    <S.Description><b>2.5.</b> Que não praticará atos para quaisquer fins ilícitos, devendo ser, sob qualquer circunstância, observada a legislação vigente à época;</S.Description>
                    <S.Description><b>2.6.</b> Que não praticará atos contrários à moral e aos bons costumes e/ou que violem direitos de terceiros;</S.Description>
                    <S.Description><b>2.7.</b> Que não transmitirá por meio da Plataforma conteúdo de natureza erótica, pornográfica, obscena, difamatória ou caluniosa, que faça apologia ao crime, uso de drogas, consumo de bebidas ou violência, ou que promova ou incite o preconceito e a discriminação;</S.Description>
                    <S.Description><b>2.8.</b> Ter ciência de que quaisquer informações e/ou ofertas apresentadas poderão ser modificadas a qualquer tempo pelo {termVariables.COMPANY_NAME}, a seu exclusivo critério, sem que nenhuma indenização lhe seja devida em razão disto;</S.Description>
                    <S.Description><b>2.9.</b> Reconhecer que não poderá ser inserido qualquer conteúdo capaz de incorporar elementos nocivos na Plataforma, por quaisquer meios capazes de impedir o seu adequado funcionamento, bem como de prejudicar os sistemas informáticos do {termVariables.COMPANY_NAME} ou de terceiros;</S.Description>
                    <S.Description><b>2.10.</b> Entender que, em caso de suspeita de ocorrência de fraude, sua contratação poderá ser recusada, sem que nada lhe seja devido em decorrência de tal fato;</S.Description>
                    <S.Description><b>2.11.</b> Estar ciente de que deverá notificar o {termVariables.COMPANY_NAME}, imediatamente, pelos Canais de Atendimento indicados no item 21 desta Política, sobre qualquer uso não autorizado de seus dados, sua conta, seu celular ou dispositivo móvel, ou acesso não autorizado por terceiros; e</S.Description>
                    <S.Description><b>2.12.</b> Compreender que a realização do processo de contratação por meio de conexão em redes wi-fi públicas e desprotegidas oferece risco às informações armazenadas em seu dispositivo móvel, bem como aos dados e/ou informações nele armazenados. Assim, Você declara estar ciente de que deverá assegurar a confiabilidade das redes por meio das quais realizará o processo de contratação, sendo de sua única e exclusiva responsabilidade quaisquer danos advindos de seu uso mediante acesso às redes públicas consideradas inseguras, uma vez que o {termVariables.COMPANY_NAME} não possui qualquer ingerência nas medidas de segurança adotadas por tais redes.</S.Description>
                    
                    <S.Subtitle>3. Contratação</S.Subtitle>
    
                    <S.Description>
                        Para a contratação de seu empréstimo via assinatura eletrônica na Plataforma, Você declara, desde já, reconhecer que o aceite às condições disponibilizadas pelo {termVariables.COMPANY_NAME} por meio da Plataforma, em conjunto com o evento de biometria facial, coletada por meio de sua foto, representam a sua assinatura eletrônica, reconhecendo sua validade jurídica para a formalização da presente contratação.
                    </S.Description>
    
                    <S.Description>
                    <b>3.1.</b> Já para a contratação de empréstimo por assinatura a rogo do cliente (aplicável para cliente analfabeto, portador de necessidades especiais e/ou impedido de assinar), sua validade está condicionada à assinatura, na presença de duas testemunhas, do contrato físico ou eletrônico, em conjunto com o envio de biometria facial, coletada por meio de sua foto, que será usada na sua identificação, como medida de prevenção à fraude.
                    </S.Description>
    
                    <S.Description>
                    <b>3.1.1.</b> O rogado deverá realizar a leitura em voz alta de todos os termos, condições e documentos apresentados na Plataforma e os aceites somente deverão ser confirmados após o cliente ser questionado sobre a compreensão de todo o conteúdo e concordar expressamente.
                    </S.Description>
    
                    <S.Subtitle>4. Links de Terceiros</S.Subtitle>
                    <S.Description>
                        No caso de atendimento pelo Whatsapp do {termVariables.COMPANY_NAME}, referida Plataforma dispõe de seus próprios termos de uso e de sua própria política de privacidade, os quais não estão diretamente relacionados a esta Política. Você está ciente e concorda que a existência destes links não representa um endosso ou patrocínio a sites terceiros e reconhece estar sujeito aos termos e uso e políticas de privacidade de empresas terceiras, os quais deverão ser verificados por Você. Deste modo, Você compreende que o {termVariables.COMPANY_NAME} não poderá, em qualquer hipótese, ser responsabilizado pelo conteúdo de sites de terceiros ou pelas condições indicadas em seus respectivos termos de uso e política de privacidade.
                    </S.Description>
    
                    
                    <S.Subtitle>5. Isenções de Responsabilidade</S.Subtitle>
                    <S.Description>
                        O {termVariables.COMPANY_NAME} não será, em qualquer hipótese, responsabilizado por danos diretos e/ou indiretos decorrentes:
                    </S.Description>
    
                    <S.Description>
                        <b>5.1.</b> De quaisquer indisponibilidades, erros e/ou falhas a Plataforma;
                    </S.Description>
                    <S.Description>
                        <b>5.2.</b> De eventuais erros e/ou inconsistências na transmissão de dados da rede, bem como relacionados à qualidade ou disponibilidade da conexão de internet capazes de obstar o adequado recebimento de informações pelo {termVariables.COMPANY_NAME} ou por Você;
                    </S.Description>
                    <S.Description>
                        <b>5.3.</b> De dados desatualizados, incompletos e/ou inverídicos eventualmente fornecidos por Você;
                    </S.Description>
                    <S.Description>
                        <b>5.4.</b> Do uso da Plataforma em desacordo com esta Política;
                    </S.Description>
                    <S.Description>
                        <b>5.5.</b> Do conhecimento que terceiros não autorizados possam ter acesso aos dados disponibilizados por meio da Plataforma, em decorrência de falha exclusivamente relacionada a Você ou a terceiros, que fujam ao controle razoável do {termVariables.COMPANY_NAME};
                    </S.Description>
                    <S.Description>
                        <b>5.6.</b> Da presença de vírus ou demais elementos nocivos na Plataforma, capazes de causar alterações em seus sistemas informáticos (software e hardware) ou em documentos eletrônicos armazenados em seus sistemas informáticos.
                    </S.Description>
                    <S.Subtitle>6. Sanções</S.Subtitle>
                    <S.Description>
                        Sem prejuízo de outras medidas cabíveis, Você compreende que o {termVariables.COMPANY_NAME} poderá, a seu exclusivo critério, recusar a contratação, temporária ou definitivamente, se:
                    </S.Description>
                    <S.Description>
                        <b>6.1.</b> Você descumprir as disposições constantes nesta Política;
                    </S.Description>
                    <S.Description>
                        <b>6.2.</b> Existirem suspeitas de que Você pratica ou praticou atos fraudulentos ou ilegais; o que poderá motivar a exclusão de todos os seus dados, sem qualquer tipo de indenização ou compensação por conta disso; e
                    </S.Description>
                    <S.Description>
                        <b>6.3.</b> Caso não seja possível a verificação de sua identidade, ou se os dados e informações fornecidos por Você estiverem incorretos.
                    </S.Description>
                    <S.Subtitle>7. Propriedade Intelectual</S.Subtitle>
                    <S.Description>
                        Você compreende que cada um dos textos, imagens, fotografias, projetos, dados, vídeos, ilustrações, ícones, tecnologias, links e demais conteúdos audiovisuais ou sonoros, incluindo o software da Plataforma, desenhos gráficos e/ou códigos fonte, dentre outros, são de propriedade exclusiva do {termVariables.COMPANY_NAME} ou de terceiros que tenham autorizado sua utilização na Plataforma, estando protegidos pelas leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, os termos das Leis 9.279/96, 9.609/98 e 9.610/98.
                    </S.Description>
                    <S.Description>
                        <b>7.1.</b> As marcas, nomes comerciais ou logotipo de qualquer espécie apresentados por meio da Plataforma são de propriedade do {termVariables.COMPANY_NAME} ou de terceiros que tenham permitido o seu uso, de modo que a utilização da Plataforma não importa em autorização para que Você possa citar tais marcas, nomes comerciais e logotipos.
                    </S.Description>
                    <S.Description>
                        <b>7.2.</b> Caso Você identifique a violação de direitos relativos à propriedade intelectual, poderá enviar uma denúncia por meio de um de nossos Canais de Atendimento indicados no item 21 desta Política, para que o {termVariables.COMPANY_NAME} averigue a situação indicada, e adote as medidas necessárias para a apuração e resolução de eventual irregularidade.
                    </S.Description>
                    <S.Subtitle>8. Duração ou Finalização do Acesso</S.Subtitle>
                    <S.Description>
                        As Plataformas serão disponibilizadas por prazo indeterminado, sendo reservado ao {termVariables.COMPANY_NAME} o direito de modificar, suspender ou descontinuar o acesso à determinada Plataforma, a qualquer tempo, mediante aviso prévio para adoção das medidas cabíveis.
                    </S.Description>
                    <S.Subtitle>9. Dados para a Contratação</S.Subtitle>
                    <S.Description>
                        No processo de contratação do empréstimo, tão logo sua proposta de crédito seja localizada com base no número do seu CPF, serão realizadas algumas verificações a fim de garantir a sua segurança. Para tanto, o {termVariables.COMPANY_NAME} poderá solicitar a Você uma fotografia de seu RG, CNH ou carteira de classe (OAB, CREA etc.), frente e verso, os aceites necessários da(s) operação(ões) de crédito, sendo que a contratação do empréstimo é confirmada mediante coleta de sua foto com match de seus dados biométricos e a realização de prova de vida, que consistem na captura de suas características biológicas, físicas e comportamentais individuais e únicas, por meio da coleta de imagens e fotos de seu rosto pela câmera do seu smartphone.
                    </S.Description>
    
                    <S.Description>
                        Você compreende e autoriza que, para o processo de contratação, além dos dados acima indicados, poderão ser coletados:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>(I) Seus dados cadastrais:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) nome civil;</S.ListItem>
                                    <S.ListItem>(b) nome social;</S.ListItem>
                                    <S.ListItem>(c) e-mail;</S.ListItem>
                                    <S.ListItem>(d) n° do CPF;</S.ListItem>
                                    <S.ListItem>(e) número de celular;</S.ListItem>
                                    <S.ListItem>(f) n° do RG, CNH ou carteira de classe;</S.ListItem>
                                    <S.ListItem>(g) endereço;</S.ListItem>
                                    <S.ListItem>(h) país de residência e de nascimento;</S.ListItem>
                                    <S.ListItem>(i) declaração de pessoa exposta politicamente;</S.ListItem>
                                    <S.ListItem>(j) sexo;</S.ListItem>
                                    <S.ListItem>(k) estado civil;</S.ListItem>
                                    <S.ListItem>(l) profissão;</S.ListItem>
                                    <S.ListItem>(m) nome da mãe;</S.ListItem>
                                    <S.ListItem>(n) data e local de nascimento;</S.ListItem>
                                    <S.ListItem>(o) nacionalidade;</S.ListItem>
                                    <S.ListItem>(p) fotos de seu próprio rosto, com a finalidade de capturar dados biométricos;</S.ListItem>
                                    <S.ListItem>(q) prova de vida (com a coleta de dados biométricos de seu rosto por meio de fotos);</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                            <S.ListItem><b>(II) seus dados financeiros:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) renda mensal;</S.ListItem>
                                    <S.ListItem>(b) patrimônio;</S.ListItem>
                                    <S.ListItem>(c) operações de crédito já contratadas com o Banco ou com outras instituições;</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                            <S.ListItem><b>(III) seus documentos pessoais, por exemplo:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) cópias físicas ou digitalizadas do seu RG, CNH ou carteira de classe;</S.ListItem>
                                    <S.ListItem>(b) comprovantes de renda;</S.ListItem>
                                    <S.ListItem>(c) extrato bancário, quando Você tiver uma conta salário, para fins de comprovação do recebimento do seu soldo ou salário.</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        Além disso, para fins de cumprimento da Instrução Normativa no 138/2022, você compreende e autoriza, mediante assinatura de termo em separado, que o INSS/DATAPREV disponibilize as seguintes informações:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>(I) Dados de identificação:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) CPF;</S.ListItem>
                                    <S.ListItem>(b) data de nascimento;</S.ListItem>
                                    <S.ListItem>(c) nome completo;</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                            <S.ListItem><b>(II) Dados do benefício:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) número do benefício;</S.ListItem>
                                    <S.ListItem>(b) situação do benefício;</S.ListItem>
                                    <S.ListItem>(c) espécie do benefício;</S.ListItem>
                                    <S.ListItem>(d) indicação de que o benefício foi concedido por liminar;</S.ListItem>
                                    <S.ListItem>(e) data de Cessação do Benefício - DCB (se houver);</S.ListItem>
                                    <S.ListItem>(f) se possui representante legal;</S.ListItem>
                                    <S.ListItem>(g) se possui procurador;</S.ListItem>
                                    <S.ListItem>(h) se possui entidade de representação;</S.ListItem>
                                    <S.ListItem>(i) se há pensão alimentícia;</S.ListItem>
                                    <S.ListItem>(j) se está bloqueado para empréstimo;</S.ListItem>
                                    <S.ListItem>(k) qual a data da última perícia médica;</S.ListItem>
                                    <S.ListItem>(l) qual a data do Despacho do Benefício – DDB;</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                            <S.ListItem><b>(III) Dados de pagamento do benefício:</b></S.ListItem>
                            <S.Indent>
                                <S.UnorderedList>
                                    <S.ListItem>(a) UF onde o Beneficiário recebe os proventos;</S.ListItem>
                                    <S.ListItem>(b) Tipo de Crédito (Cartão ou Conta-Corrente);</S.ListItem>
                                    <S.ListItem>(c) Indicação da Instituição Financeira que paga o Benefício;</S.ListItem>
                                    <S.ListItem>(d) Agência Pagadora;</S.ListItem>
                                    <S.ListItem>(e) Conta-Corrente onde o Benefício é pago;</S.ListItem>
                                    <S.ListItem>(f) Margem Consignável Disponível;</S.ListItem>
                                    <S.ListItem>(g) Margem Consignável Disponível para Cartão;</S.ListItem>
                                    <S.ListItem>(h) Valor Limite para Cartão;</S.ListItem>
                                    <S.ListItem>(i) Quantidade de Empréstimos ativos/suspensos.</S.ListItem>
                                </S.UnorderedList>
                            </S.Indent>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        <b>9.1.</b> Lembre-se que Você é responsável pela qualidade das fotos fornecidas, bem como pela veracidade e atualidade dos dados informados. Quaisquer prejuízos havidos em decorrência do fornecimento de informações inverídicas ou desatualizadas será de sua única e exclusiva responsabilidade.
                    </S.Description>
    
                    <S.Description>
                        <b>9.1.1</b> Para lhe conferir maior segurança, garantir a veracidade de suas informações e a prevenção contra fraudes, bem como para fins de análise cadastral e de crédito Você autoriza o {termVariables.COMPANY_NAME} a realizar buscas em bureaus públicos e privados, inclusive mediante compartilhamento de dados biométricos, para apuração de eventuais inconsistências em seus dados pessoais, e a solicitar dados pessoais e/ou documentos adicionais, que entender pertinentes. Você compreende que, a depender do resultado de tais consultas, ou, ainda, caso Você se negue a fornecer eventuais dados pessoais adicionais solicitados, o {termVariables.COMPANY_NAME} poderá recusar a contratação de produtos e serviços por Você, sem prejuízo de outras medidas que entender aplicáveis.
                    </S.Description>
    
                    <S.Subtitle>
                        10. Sistema de Informações de Crédito do Banco Central (“SCR”)
                    </S.Subtitle>
                    <S.Description>
                        Ao aceitar esta Política, em observância à regulamentação expedida pelo Banco Central (“BACEN”), Você autoriza o {termVariables.COMPANY_NAME} a:
                    </S.Description>
    
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>i)</b> consultar as informações existentes a seu respeito no SCR;</S.ListItem>
                            <S.ListItem><b>ii)</b> compartilhar com o BACEN, para integrar o SCR, informações relacionadas ao crédito representado na operação de empréstimo, se contratada.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        <b>10.1.</b> Você se declara ciente de que o SCR tem por finalidade fornecer ao BACEN informações para fins de supervisão do risco de crédito a que estão expostas as instituições financeiras, bem como propiciar o intercâmbio de informações entre estas instituições com o objetivo de subsidiar decisões de crédito e de negócios, e a consequente concessão de crédito e/ou oferta de outros produtos e serviços, conforme o caso.
                    </S.Description>
    
                    <S.Description>
                        <b>10.2.</b> Caso Você entenda serem necessárias correções, exclusões e/ou manifestações de discordância com relação às informações constantes no SCR, deverá encaminhar requerimento escrito e fundamentado ou, quando for o caso, a respectiva decisão judicial, à Central de Atendimento ao Público do BACEN (ou à instituição responsável pela remessa de tais informações).
                    </S.Description>
    
                    <S.Subtitle>
                        11. Tratamento de Dados Pessoais
                    </S.Subtitle>
                    <S.Description>
                        O {termVariables.COMPANY_NAME} coletará e armazenará as informações fornecidas por Você, como indicado no item 9 desta Política, em conjunto com algumas informações geradas automaticamente, tais como, as características do dispositivo de acesso, do navegador, protocolo de internet (IP, data, hora, minuto, segundo e porta lógica), log de acesso, informações, aplicativos e telas acessadas, dados de geolocalização, cookies, pixel tags, beacons, local shared objects, device fingerprint (identificação para reconhecer um determinado dispositivo móvel), número de telefone, nome, status e fotografia constantes no perfil utilizado quando da contratação do empréstimo via Plataforma.
                    </S.Description>
    
                    <S.Description>
                        <b>11.1.</b> Você compreende que, para a sua identificação e segurança contra fraudes de terceiros mal-intencionados, o {termVariables.COMPANY_NAME} coletará dados biométricos para o que Você declara estar ciente e de acordo, inclusive para o compartilhamento destes dados pelo {termVariables.COMPANY_NAME} com empresas prestadoras de serviços que visem evitar a ocorrência de fraudes.
                    </S.Description>
    
                    <S.Subtitle>
                        12. Finalidades para o Uso de Dados Pessoais
                    </S.Subtitle>
                    <S.Description>
                        Você declara estar ciente e concordar que os dados fornecidos por Você serão utilizados pelo {termVariables.COMPANY_NAME} para as seguintes finalidades:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>i)</b> desenvolver, manter e aperfeiçoar os recursos e/ou produtos fornecidos pelo {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>ii)</b> viabilizar a análise de crédito a ser eventualmente ofertado;</S.ListItem>
                            <S.ListItem><b>iii)</b> contratar e/ou confirmar a contratação de serviços e produtos do {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>iv)</b> garantir a prevenção à fraude;</S.ListItem>
                            <S.ListItem><b>v)</b> assegurar a prevenção à lavagem de dinheiro;</S.ListItem>
                            <S.ListItem><b>vi)</b> realizar análises relacionadas à segurança, aperfeiçoamento e desenvolvimento de ferramentas antifraude;</S.ListItem>
                            <S.ListItem><b>vii)</b> permitir o fornecimento de serviços mais personalizados e adequados às suas necessidades, tais como conteúdos e anúncios relevantes;</S.ListItem>
                            <S.ListItem><b>viii)</b> permitir a comunicação entre Você e o {termVariables.COMPANY_NAME}, inclusive mediante o envio e recebimento de e-mails, contatos telefônicos e/ou envio de mensagens pelas redes sociais;</S.ListItem>
                            <S.ListItem><b>ix)</b> enviar comunicações a respeito de empréstimos {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>x)</b> consultar ofertas disponíveis para Você para outros produtos {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>xi)</b> consultar suas informações registradas no FGTS para eventual análise do empréstimo saque aniversário, se for o caso;</S.ListItem>
                            <S.ListItem><b>xii)</b> consultar suas informações registradas no INSS e sua respectiva margem consignável para eventual análise do empréstimo consignado;</S.ListItem>
                            <S.ListItem><b>xiii)</b> consultar suas informações registradas em seu órgão pagador, conforme o caso, para oferta de produtos e serviços.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        <b>12.1.</b> O {termVariables.COMPANY_NAME} preserva a sua privacidade e não compartilhará seus dados e informações com terceiros salvo mediante seu consentimento, por força da lei ou ordem judicial, e nas hipóteses previstas no item 15 desta Política.
                    </S.Description>
    
                    <S.Subtitle>
                        13. Política de Cookies
                    </S.Subtitle>
                    <S.Description>
                        Os cookies são pequenos arquivos que podem ou não ser adicionados no seu dispositivo eletrônico, e que permitem armazenar e reconhecer dados de sua navegação. Em sua navegação poderão ser utilizados 04 (quatro) tipos de cookies:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>a)</b> Cookies de Autenticação: reconhecem um determinado usuário, possibilitando o acesso e utilização da Plataforma com conteúdo e/ou serviços restritos, proporcionando experiências de navegação mais personalizadas.</S.ListItem>
                            <S.ListItem><b>b)</b> Cookies de Segurança: ativam recursos de segurança da Plataforma para ajudar no monitoramento e/ou detecção de atividades maliciosas ou vedadas por esta Política, e para proteger as informações dos usuários do acesso por terceiros não autorizados.</S.ListItem>
                            <S.ListItem><b>c)</b> Cookies de Pesquisa, Análise e Desempenho: ajudam a entender o desempenho da Plataforma, medir a audiência, verificar seus hábitos de navegação, bem como a forma pela qual chegou na página da Plataforma (por exemplo, através de links de outros sites, buscadores ou diretamente pelo endereço).</S.ListItem>
                            <S.ListItem><b>d)</b> Cookies de Propaganda: apresentam publicidade relevante a seus interesse, dentro e fora da Plataforma ou em sites de parceiros. É usado também bem para saber se os usuários que visualizaram a publicidade visitaram a Plataforma. Podem ser utilizados, ainda, para lembrar eventuais pesquisas anteriores, e apresentar aos usuários anúncios relacionados a seus interesses.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        <b>13.1.</b> Você poderá desabilitar determinados cookies por meio das opções de configuração do seu respectivo navegador (com exceção dos cookies destinados a garantir sua segurança). Contudo, ao fazê-lo, Você está ciente de que é possível que a Plataforma não desempenhe todas as suas funcionalidades.
                    </S.Description>
    
                    <S.Subtitle>
                        14. Medidas de Segurança
                    </S.Subtitle>
                    <S.Description>
                        Você compreende que todos os dados pessoais coletados por meio da Plataforma serão armazenados em servidores próprios ou de terceiros, e são considerados confidenciais pelo {termVariables.COMPANY_NAME}, que se compromete a adotar todos os esforços razoáveis de mercado para preservar a segurança de seus dados, entre eles a observância das seguintes diretrizes:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>a)</b> Utilização de métodos padrões de mercado para criptografar os dados coletados para garantir sua inviolabilidade;</S.ListItem>
                            <S.ListItem><b>b)</b> Emprego de softwares de alta tecnologia para proteção contra o acesso não autorizado aos sistemas, sendo estes considerados ambientes controlados e de segurança;</S.ListItem>
                            <S.ListItem><b>c)</b> Disponibilização de acesso a locais de armazenamento de dados pessoais apenas a pessoas previamente autorizadas, comprometidas ao sigilo de tais dados, inclusive mediante a assinatura de termo de confidencialidade;</S.ListItem>
                            <S.ListItem><b>d)</b> Aplicação de mecanismos de autenticação de acesso aos registros capazes de individualizar o responsável pelo tratamento e acesso dos dados coletados em decorrência da utilização da Plataforma;</S.ListItem>
                            <S.ListItem><b>e)</b> Manutenção de inventário indicando momento, duração, identidade do funcionário ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto n°8.771/2016.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
    
                    <S.Description>
                        <b>14.1.</b> Você se compromete a manter o aplicativo para celular sempre atualizado de acordo com a última versão disponível nas lojas oficiais de aplicativos para dispositivos Apple (App Store), Android (Google Play Store). Você também se compromete a possuir smartphone que, por discricionariedade do {termVariables.COMPANY_NAME}, possua os requisitos mínimos que permitam o uso e a atualização do aplicativo sempre que necessário.
                    </S.Description>
    
                    <S.Subtitle>
                        15. Compartilhamento de Dados Pessoais
                    </S.Subtitle>
                    <S.Description>
                        Você autoriza o {termVariables.COMPANY_NAME} a compartilhar os dados coletados por meio da Plataforma, para as finalidades acima indicadas, adotando sempre as medidas de segurança necessárias, com empresas sediadas no Brasil ou no exterior, nos seguintes casos:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>a)</b> Se necessário para a prestação dos serviços ofertados pelo {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>b)</b> Para a sua segurança, visando a prevenção à fraude nos processos de realização de cadastro ou de transações na Plataforma, com fornecedores especializados em garantir a autenticidade destas transações e/ou instituições financeiras;</S.ListItem>
                            <S.ListItem><b>c)</b> Para a proteção dos interesses do {termVariables.COMPANY_NAME} em caso de conflito, inclusive em demandas judiciais;</S.ListItem>
                            <S.ListItem><b>d)</b> Em caso de transações e alterações societárias envolvendo o {termVariables.COMPANY_NAME}, hipótese em que a transferência dos dados será necessária para a continuidade dos serviços ofertados;</S.ListItem>
                            <S.ListItem><b>e)</b> Com fornecedores e instituições integrantes do conglomerado financeiro do {termVariables.COMPANY_NAME}, para fins publicitários, estatísticos, ofertas de produtos e serviços e para a prestação dos serviços relacionados às funcionalidades da Plataforma;</S.ListItem>
                            <S.ListItem><b>f)</b> Mediante ordem judicial ou por requerimento de autoridades administrativas que detenham competência legal para a sua requisição; e/ou</S.ListItem>
                            <S.ListItem><b>g)</b> Com fornecedores responsáveis pela análise da qualidade da prestação dos serviços e produtos ofertados por meio da Plataforma, assim como segmentação dos usuários e clientes para melhor compreender seus comportamentos.</S.ListItem>
                            <S.ListItem><b>h)</b> Para cumprimento de obrigação legal e/ou regulatória perante autoridades;</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
                    
                    <S.Subtitle>
                        16. Exclusão dos Dados Pessoais
                    </S.Subtitle>
                    <S.Description>
                        O {termVariables.COMPANY_NAME} poderá excluir suas informações quando: 
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><strong>(i)</strong> a finalidade para a qual a informação foi coletada tenha sido alcançada ou quando os dados deixarem de ser necessários para o alcance desta finalidade;</S.ListItem>
                            <S.ListItem><strong>(ii)</strong> quando da revogação do consentimento fornecido por Você para as hipóteses de tratamento de dados pessoais baseadas no seu consentimento ou</S.ListItem>
                            <S.ListItem><strong>(iii)</strong> mediante determinação de autoridade competente para tanto.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent> 
                    
                    <S.Description>
                        <b>16.1.</b>Você compreende que a exclusão de determinados dados poderá impossibilitar a disponibilização de certos produtos, em decorrência de seu aspecto operacional, sem que qualquer indenização lhe seja devida pelo {termVariables.COMPANY_NAME} em razão disto. O {termVariables.COMPANY_NAME} reserva para si o direito de manter armazenados todos os dados necessários ao cumprimento dos contratos que possua com Você e da legislação brasileira vigente, mesmo em caso de requisição de sua exclusão.
                    </S.Description>
    
                    <S.Subtitle>
                        17. Seus Direitos em Relação ao Tratamento de Dados Pessoais
                    </S.Subtitle>
                    <S.Description>
                        Você poderá requerer ao {termVariables.COMPANY_NAME}:
                    </S.Description>
                    <S.Indent>
                        <S.UnorderedList>
                            <S.ListItem><b>a)</b> A confirmação da existência de tratamento de seus dados pessoais e o acesso aos seus dados coletados pelo {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>b)</b> A correção de seus dados, caso estes estejam incompletos, inexatos ou desatualizados;</S.ListItem>
                            <S.ListItem><b>c)</b> A anonimização, o bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação brasileira aplicável;</S.ListItem>
                            <S.ListItem><b>d)</b> A portabilidade de seus dados pessoais, para si ou para terceiro;</S.ListItem>
                            <S.ListItem><b>e)</b> A eliminação dos dados pessoais tratados com o seu consentimento, desde que não haja determinação legal para mantê-los registrados junto ao {termVariables.COMPANY_NAME};</S.ListItem>
                            <S.ListItem><b>f)</b> A obtenção de informações sobre entidades públicas ou privadas com as quais o {termVariables.COMPANY_NAME} compartilhou seus dados;</S.ListItem>
                            <S.ListItem><b>g)</b> Informações sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre seu consentimento, bem como de ser informado sobre suas consequências, em caso de negativa; e</S.ListItem>
                            <S.ListItem><b>h)</b> A revisão de decisões realizadas exclusivamente por meios automatizados.</S.ListItem>
                        </S.UnorderedList>
                    </S.Indent>
                    <S.Description>
                        <b>17.1.</b> Nesse sentido, para que Você possa exercer seus direitos, basta que entre em contato conosco por meio de um dos nossos Canais de Atendimento indicados no item 21 desta Política, apontando suas dúvidas e/ou requerimentos relacionados a seus dados pessoais, sendo certo que o {termVariables.COMPANY_NAME} empregará os melhores esforços para atendê-lo no menor tempo possível.
                    </S.Description>
                    <S.Subtitle>
                        18. Cadastro Positivo
                    </S.Subtitle>
                    <S.Description>
                        De acordo com a Lei Complementar n°166/2019, o {termVariables.COMPANY_NAME} possui como obrigação fornecer dados financeiros e de pagamentos relativos a operações de crédito e obrigações de pagamento adimplidas ou em andamento a gestores de bancos de dados para formação de histórico de crédito. Entretanto, Você pode exercer seus direitos relativos aos seus dados pessoais e informações constantes nos bancos de dados dos gestores dos referidos bancos de dados, por meio dos canais oficiais disponibilizados pelos gestores dos bancos de dados.
                    </S.Description>
                    
                    <S.Description>
                        <b>18.1.</b> Por meio do aceite aos presentes termos, você autoriza os gestores de banco de dados de que trata a Lei No 12.414/2011, a disponibilizarem ao Banco {termVariables.COMPANY_NAME}, CNPJ no {termVariables.DOCUMENT_NUMBER} (“{termVariables.COMPANY_NAME}”) o seu histórico de crédito, o qual abrangerá os dados financeiros e de pagamentos relativos às operações de crédito e obrigações de pagamento adimplidas em seus respectivos vencimentos e aquelas a vencer, constantes de banco(s) de dados, com a finalidade única e exclusiva de subsidiar a análise e a eventual concessão de crédito, a venda a prazo ou outras transações comerciais e empresariais que impliquem risco financeiro. Você se declara ciente de que esta autorização tem validade por tempo indeterminado, limitada ao período de duração do relacionamento contratual estabelecido entre você e o {termVariables.COMPANY_NAME}. Você ainda se declara ciente de que poderá revogar, a qualquer tempo, esta autorização, assim como requerer a exclusão de seu cadastro através dos canais de comunicação indicados no item 21, caso entenda pertinente.
                    </S.Description>
    
                    <S.Subtitle>
                        19. Disposições Gerais
                    </S.Subtitle>
                    
                    <S.Description>
                        <b>19.1.</b> Comunicações: Todas as comunicações encaminhadas por Você ao {termVariables.COMPANY_NAME} serão consideradas plenamente válidas quando realizadas por meio dos Canais de Atendimento ao Cliente, indicados no item 21 deste documento. Por outro lado, todas as comunicações enviadas pelo {termVariables.COMPANY_NAME} serão consideradas válidas quando realizadas por meio de qualquer das informações de contato disponibilizadas por Você. ATENÇÃO! Em qualquer hipótese de recebimento de e-mails encaminhados pelo {termVariables.COMPANY_NAME}, especialmente os que se destinam a solicitar dados ou o pagamento de contas, Você deverá analisar atentamente o emissor de tal mensagem a fim de evitar condutas fraudulentas. O {termVariables.COMPANY_NAME} não solicitará, em qualquer hipótese, senhas ou a confirmação de dados pessoais por e-mail. Caso suspeite da mensagem encaminhada, Você deverá entrar em contato com o {termVariables.COMPANY_NAME} por meio dos Canais de Atendimento.
                    </S.Description>
                    <S.Description>
                        <b>19.2.</b> Declaração de Nulidade. Qualquer cláusula ou condição desta Política que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições desta Política, as quais permanecerão plenamente válidas, gerando efeitos em sua integral extensão.
                    </S.Description>
    
                    <S.Description>
                        <b>19.3.</b> Tolerância. A falha do {termVariables.COMPANY_NAME} em exigir quaisquer direitos ou disposições da presente Política não constituirá renúncia, podendo este exercer regularmente o seu direito, dentro dos prazos legais.
                    </S.Description>
    
                    <S.Subtitle>
                        20. Legislação e Foro
                    </S.Subtitle>
                    <S.Description>
                        Esta Política é regida de acordo com a legislação brasileira. Fica eleito o Foro do seu domicílio para dirimir quaisquer dúvidas, questões ou litígios decorrentes da presente Política, renunciando as partes a qualquer outro, por mais privilegiado que seja.
                    </S.Description>
    
                    <S.Subtitle>
                        21. Canais de Atendimento
                    </S.Subtitle>
                    <S.Description style={{ textAlign: 'initial' }}>
                        Central de Relacionamento: {termVariables.CONTACT_INFORMATION_RAW}
                    </S.Description>
                </S.TermContainer>
                {error.has_error &&
                    <ErrorPage
                        error={error}
                        redirectToLogin={() => setSignerStep("login")}
                        resetError={() => setError(initialError)}
                        clientThemeSettings={clientThemeSettings}
                    />
                }
                <S.Footer>
                    <ThemedButton
                        clientThemeSettings={clientThemeSettings}
                        loading={false}
                        onClick={handleClick}
                    >
                        Aceitar e continuar
                    </ThemedButton>
                    <PoweredByQITech />
                </S.Footer>
            </G.Container>
        );
    }

    return (
        <Wallpaper backgroundColor={clientThemeSettings["background_color"]} style={{ padding: '10px' }}>
            <Box className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)", marginBottom: "110px" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />
                <Box style={{width: '95%'}}>
                    {(
                        <Box>
                            <Box style={{textAlign: "center"}}>
                            <DataDisplayTitle className="title-level1">
                            Termo de Uso e Política de Privacidade
                            </DataDisplayTitle>
                            </Box>
                            <DataDisplayDescription className="description-level2">
                            <b>O que você vai encontrar aqui?</b>
                            </DataDisplayDescription>
                            <DataDisplayDescription className="description-level2">
                            Nos Termos de Uso e Política de Privacidade do {termVariables.COMPANY_NAME}, você encontra as regras para a contratação de empréstimo, incluindo o tratamento de dados pessoais, pelos canais digitais de atendimento disponibilizados pelo {termVariables.COMPANY_NAME}.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            Neste documento, estão as regras para o uso das plataformas, os documentos necessários para a contratação do empréstimo e suas responsabilidades durante o relacionamento com o {termVariables.COMPANY_NAME}.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>Lembre-se:</b> este documento pode ser atualizado periodicamente, sem a necessidade de aviso prévio, para garantir ainda mais segurança para você.     
                            </DataDisplayDescription>

                            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                                <Box
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                        padding: '10px 15px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        backgroundColor: '#f9f9f9',
                                        outline: 'none',
                                        WebkitTapHighlightColor: 'transparent',
                                        margin: '0 auto',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                    type='primary'>
                                    <DataDisplayDescription><b>Índice</b></DataDisplayDescription>
                                        <ul>
                                        <li>Aceite da Política</li>
                                        <li>Regras para Uso da Plataforma</li>
                                        <li>Contratação</li>
                                        <li>Links de Terceiros</li>
                                        <li>Isenções de Responsabilidade</li>
                                        <li>Sanções</li>
                                        <li>Propriedade Intelectual</li>
                                        <li>Duração ou Finalização do Acesso</li>
                                        <li>Dados para Contratação</li>
                                        <li>Sistema de Informações de Crédito do Banco Central (SCR)</li>
                                        <li>Tratamento de Dados Pessoais</li>
                                        <li>Finalidades para Uso de Dados Pessoais</li>
                                        <li>Política de Cookies</li>
                                        <li>Medidas de Segurança</li>
                                        <li>Compartilhamento de Dados Pessoais</li>
                                        <li>Exclusão dos Dados Pessoais</li>
                                        <li>Seus Direitos em Relação ao Tratamento de Dados Pessoais</li>
                                        <li>Cadastro Positivo</li>
                                        <li>Disposições Gerais</li>
                                        <li>Legislação e Foro</li>
                                        <li>Canais de Atendimento</li>
                                        </ul>
                                </Box>
                            </Box>
                            <DataDisplayDescription className="description-level2">
                            Termos de Uso e Política de Privacidade
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            Por meio destes Termos de Uso e Política de Privacidade (“Política”), o {termVariables.COMPANY_NAME}, com sede na cidade de {termVariables.CITY}, Estado de {getUfFullName(termVariables.UF)}, na {termVariables.STREET}, {termVariables.NUMBER} - {termVariables.NEIGHBORHOOD}, {termVariables.CITY} - {termVariables.UF}, CEP {termVariables.ZIP_CODE} inscrito no CNPJ sob o no {termVariables.DOCUMENT_NUMBER} (“{termVariables.COMPANY_NAME}”) dispõe sobre as regras dos canais digitais de atendimento disponibilizados pelo {termVariables.COMPANY_NAME} (Plataforma) para a contratação de empréstimo, incluindo o tratamento de dados pessoais, pelo cliente qualificado na proposta de crédito (“Você”), por meio.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            Você compreende que, a fim de atender às suas necessidades e lhe conferir maior segurança, este documento está sujeito a contínuo aprimoramento e estará sempre disponível em nosso site. Assim, Você entende ser de sua exclusiva responsabilidade consultar esta Política de tempos em tempos, a fim de verificar suas eventuais alterações.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>1. Aceite da Política</b><br />
                            Ao dar início ao processo de contratação de empréstimo, Você deverá ler o conteúdo desta Política, e, se concordar com as condições apresentadas, manifestar sua concordância, por meio do envio de mensagem ou aceite atestando tal fato por meio da Plataforma. Você poderá revisar o consentimento para determinadas finalidades nesta Política, quando assim expressamente indicado, por meio de um de nossos Canais de Atendimento indicados no item 21 deste documento. Entretanto, ao revogar o seu consentimento, Você compreende que isto poderá restringir, suspender ou cancelar alguns dos serviços ofertados pelo {termVariables.COMPANY_NAME}. De todo modo, assim que o {termVariables.COMPANY_NAME} receber sua solicitação, a depender do caso, seus dados pessoais serão excluídos, exceto caso o seu armazenamento decorra de obrigação legal ou de obrigações contratuais assumidas por Você ainda pendentes.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>2. Regras para Uso da Plataforma</b><br />
                            Ao aceitar esta Política, Você declara:
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>2.1.</b> Ser maior de 18 anos;<br />
                            <b>2.2.</b> Ser responsável por quaisquer consequências relacionadas à celebração desta contratação, respondendo, inclusive perante terceiros, por qualquer reivindicação que venha a ser apresentada ao {termVariables.COMPANY_NAME} judicial ou extrajudicialmente;<br />
                            <b>2.3.</b> Ser o único responsável pelas operações financeiras que realizar, assumindo todos os riscos a estas relacionados, inclusive em caso de eventual prejuízo;<br />
                            <b>2.4.</b> Dispor de todos os recursos e infraestrutura para acesso online da Plataforma, inclusive para a manutenção da segurança de suas informações, mediante atualização dos sistemas operacionais e utilização de medidas técnicas de segurança, os quais serão de sua exclusiva responsabilidade;<br />
                            <b>2.5.</b> Que não praticará atos para quaisquer fins ilícitos, devendo ser, sob qualquer circunstância, observada a legislação vigente à época;<br />
                            <b>2.6.</b> Que não praticará atos contrários à moral e aos bons costumes e/ou que violem direitos de terceiros;<br />
                            <b>2.7.</b> Que não transmitirá por meio da Plataforma conteúdo de natureza erótica, pornográfica, obscena, difamatória ou caluniosa, que faça apologia ao crime, uso de drogas, consumo de bebidas ou violência, ou que promova ou incite o preconceito e a discriminação;<br />
                            <b>2.8.</b> Ter ciência de que quaisquer informações e/ou ofertas apresentadas poderão ser modificadas a qualquer tempo pelo {termVariables.COMPANY_NAME}, a seu exclusivo critério, sem que nenhuma indenização lhe seja devida em razão disto;<br />
                            <b>2.9.</b> Reconhecer que não poderá ser inserido qualquer conteúdo capaz de incorporar elementos nocivos na Plataforma, por quaisquer meios capazes de impedir o seu adequado funcionamento, bem como de prejudicar os sistemas informáticos do {termVariables.COMPANY_NAME} ou de terceiros;<br />
                            <b>2.10.</b> Entender que, em caso de suspeita de ocorrência de fraude, sua contratação poderá ser recusada, sem que nada lhe seja devido em decorrência de tal fato;<br />
                            <b>2.11.</b> Estar ciente de que deverá notificar o {termVariables.COMPANY_NAME}, imediatamente, pelos Canais de Atendimento indicados no item 21 desta Política, sobre qualquer uso não autorizado de seus dados, sua conta, seu celular ou dispositivo móvel, ou acesso não autorizado por terceiros; e<br />
                            <b>2.12.</b> Compreender que a realização do processo de contratação por meio de conexão em redes wi-fi públicas e desprotegidas oferece risco às informações armazenadas em seu dispositivo móvel, bem como aos dados e/ou informações nele armazenados. Assim, Você declara estar ciente de que deverá assegurar a confiabilidade das redes por meio das quais realizará o processo de contratação, sendo de sua única e exclusiva responsabilidade quaisquer danos advindos de seu uso mediante acesso às redes públicas consideradas inseguras, uma vez que o {termVariables.COMPANY_NAME} não possui qualquer ingerência nas medidas de segurança adotadas por tais redes.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>3. Contratação</b><br />
                            Para a contratação de seu empréstimo via assinatura eletrônica na Plataforma, Você declara, desde já, reconhecer que o aceite às condições disponibilizadas pelo {termVariables.COMPANY_NAME} por meio da Plataforma, em conjunto com o evento de biometria facial, coletada por meio de sua foto, representam a sua assinatura eletrônica, reconhecendo sua validade jurídica para a formalização da presente contratação.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>3.1.</b> Já para a contratação de empréstimo por assinatura a rogo do cliente (aplicável para cliente analfabeto, portador de necessidades especiais e/ou impedido de assinar), sua validade está condicionada à assinatura, na presença de duas testemunhas, do contrato físico ou eletrônico, em conjunto com o envio de biometria facial, coletada por meio de sua foto, que será usada na sua identificação, como medida de prevenção à fraude.
                            </DataDisplayDescription>

                            <DataDisplayDescription className="description-level2">
                            <b>3.1.1.</b> O rogado deverá realizar a leitura em voz alta de todos os termos, condições e documentos apresentados na Plataforma e os aceites somente deverão ser confirmados após o cliente ser questionado sobre a compreensão de todo o conteúdo e concordar expressamente.
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                                4. LINKS DE TERCEIROS. No caso de atendimento pelo Whatsapp do {termVariables.COMPANY_NAME}, referida Plataforma dispõe de seus próprios termos de uso e de sua própria política de privacidade, os quais não estão diretamente relacionados a esta Política. Você está ciente e concorda que a existência destes links não representa um endosso ou patrocínio a sites terceiros e reconhece estar sujeito aos termos e uso e políticas de privacidade de empresas terceiras, os quais deverão ser verificados por Você. Deste modo, Você compreende que o {termVariables.COMPANY_NAME} não poderá, em qualquer hipótese, ser responsabilizado pelo conteúdo de sites de terceiros ou pelas condições indicadas em seus respectivos termos de uso e política de privacidade.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                5. ISENÇÕES DE RESPONSABILIDADE. O {termVariables.COMPANY_NAME} não será, em qualquer hipótese, responsabilizado por danos diretos e/ou indiretos decorrentes:
                            </DataDisplayDescription>
                            <DataDisplayDescription>5.1. De quaisquer indisponibilidades, erros e/ou falhas a Plataforma;</DataDisplayDescription>
                            <DataDisplayDescription>
                                5.2. De eventuais erros e/ou inconsistências na transmissão de dados da rede, bem como relacionados à qualidade ou disponibilidade da conexão de internet capazes de obstar o adequado recebimento de informações pelo {termVariables.COMPANY_NAME} ou por Você;
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                5.3. De dados desatualizados, incompletos e/ou inverídicos eventualmente fornecidos por Você;
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                5.4. Do uso da Plataforma em desacordo com esta Política;
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                5.5. Do conhecimento que terceiros não autorizados possam ter a dados disponibilizados por meio da Plataforma, em decorrência de falha exclusivamente relacionada a Você ou a terceiros, que fujam ao controle razoável do {termVariables.COMPANY_NAME};
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                5.6. Da presença de vírus ou demais elementos nocivos na Plataforma, capazes de causar alterações em seus sistemas informáticos (software e hardware) ou em documentos eletrônicos armazenados em seus sistemas informáticos.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                6. SANÇÕES. Sem prejuízo de outras medidas cabíveis, Você compreende que o {termVariables.COMPANY_NAME} poderá, a seu exclusivo critério, recusar a contratação, temporária ou definitivamente, se:
                            </DataDisplayDescription>
                            <DataDisplayDescription>6.1. Você descumprir as disposições constantes nesta Política;</DataDisplayDescription>
                            <DataDisplayDescription>
                                6.2. Existirem suspeitas de que Você pratica ou praticou atos fraudulentos ou ilegais; o que poderá motivar a exclusão de todos os seus dados, sem qualquer tipo de indenização ou compensação por conta disso; e
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                6.3. Caso não seja possível a verificação de sua identidade, ou se os dados e informações fornecidos por Você estiverem incorretos.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                7. PROPRIEDADE INTELECTUAL. Você compreende que cada um dos textos, imagens, fotografias, projetos, dados, vídeos, ilustrações, ícones, tecnologias, links e demais conteúdos audiovisuais ou sonoros, incluindo o software da Plataforma, desenhos gráficos e/ou códigos fonte, dentre outros, são de propriedade exclusiva do {termVariables.COMPANY_NAME} ou de terceiros que tenham autorizado sua utilização na Plataforma, estando protegidos pelas leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, os termos das Leis 9.279/96, 9.609/98 e 9.610/98.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                7.1. As marcas, nomes comerciais ou logotipo de qualquer espécie apresentados por meio da Plataforma são de propriedade do {termVariables.COMPANY_NAME} ou de terceiros que tenham permitido o seu uso, de modo que a utilização da Plataforma não importa em autorização para que Você possa citar tais marcas, nomes comerciais e logotipos.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                7.2. Caso Você identifique a violação de direitos relativos à propriedade intelectual, poderá enviar uma denúncia por meio de um de nossos Canais de Atendimento indicados no item 21 desta Política, para que o {termVariables.COMPANY_NAME} averigue a situação indicada, e adote as medidas necessárias para a apuração e resolução de eventual irregularidade.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                8. DURAÇÃO OU FINALIZAÇÃO DO ACESSO. As Plataformas serão disponibilizadas por prazo indeterminado, sendo reservado ao {termVariables.COMPANY_NAME} o direito de modificar, suspender ou descontinuar o acesso à determinada Plataforma, a qualquer tempo, mediante aviso prévio para adoção das medidas cabíveis.
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                9. DADOS PARA A CONTRATAÇÃO. No processo de contratação do empréstimo, tão logo sua proposta de crédito seja localizada com base no número do seu CPF, serão realizadas algumas verificações a fim de garantir a sua segurança. Para tanto, o {termVariables.COMPANY_NAME} poderá solicitar a Você uma fotografia de seu RG, CNH ou carteira de classe (OAB, CREA etc.), frente e verso, os aceites necessários da(s) operação(ões) de crédito, sendo que a contratação do empréstimo é confirmada mediante coleta de sua foto com match de seus dados biométricos e a realização de prova de vida, que consistem na captura de suas características biológicas, físicas e comportamentais individuais e únicas, por meio da coleta de imagens e fotos de seu rosto pela câmera do seu smartphone.
                            </DataDisplayDescription>

                            <>
                            <DataDisplayDescription>
                                Você compreende e autoriza que, para o processo de contratação, além dos dados acima indicados, poderão ser coletados:
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                <strong>(I) seus dados cadastrais:</strong>
                                <ul>
                                <li>(a) nome civil;</li>
                                <li>(b) nome social;</li>
                                <li>(c) e-mail;</li>
                                <li>(d) n° do CPF;</li>
                                <li>(e) número de celular;</li>
                                <li>(f) n° do RG, CNH ou carteira de classe;</li>
                                <li>(g) endereço;</li>
                                <li>(h) país de residência e de nascimento;</li>
                                <li>(i) declaração de pessoa exposta politicamente;</li>
                                <li>(j) sexo;</li>
                                <li>(k) estado civil;</li>
                                <li>(l) profissão;</li>
                                <li>(m) nome da mãe;</li>
                                <li>(n) data e local de nascimento;</li>
                                <li>(o) nacionalidade;</li>
                                <li>(p) fotos de seu próprio rosto, com a finalidade de capturar dados biométricos;</li>
                                <li>(q) prova de vida (com a coleta de dados biométricos de seu rosto por meio de fotos);</li>
                                </ul>
                                <strong>(II) seus dados financeiros:</strong>
                                <ul>
                                <li>(a) renda mensal;</li>
                                <li>(b) patrimônio;</li>
                                <li>(c) operações de crédito já contratadas com o Banco ou com outras instituições;</li>
                                </ul>
                                <strong>(III) seus documentos pessoais, por exemplo:</strong>
                                <ul>
                                <li>(a) cópias físicas ou digitalizadas do seu RG, CNH ou carteira de classe;</li>
                                <li>(b) comprovantes de renda;</li>
                                <li>(c) extrato bancário, quando Você tiver uma conta salário, para fins de comprovação do recebimento do seu soldo ou salário.</li>
                                </ul>
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                                Além disso, para fins de cumprimento da Instrução Normativa no 138/2022, você compreende e autoriza, mediante assinatura de termo em separado, que o INSS/DATAPREV disponibilize as seguintes informações:
                            </DataDisplayDescription>
                            <DataDisplayDescription>
                                <strong>(I) Dados de identificação:</strong>
                                <ul>
                                <li>(a) CPF;</li>
                                <li>(b) data de nascimento;</li>
                                <li>(c) nome completo;</li>
                                </ul>
                                <strong>(II) Dados do benefício:</strong>
                                <ul>
                                <li>(a) número do benefício;</li>
                                <li>(b) situação do benefício;</li>
                                <li>(c) espécie do benefício;</li>
                                <li>(d) indicação de que o benefício foi concedido por liminar;</li>
                                <li>(e) data de Cessação do Benefício - DCB (se houver);</li>
                                <li>(f) se possui representante legal;</li>
                                <li>(g) se possui procurador;</li>
                                <li>(h) se possui entidade de representação;</li>
                                <li>(i) se há pensão alimentícia;</li>
                                <li>(j) se está bloqueado para empréstimo;</li>
                                <li>(k) qual a data da última perícia médica;</li>
                                <li>(l) qual a data do Despacho do Benefício – DDB;</li>
                                </ul>
                                <strong>(III) Dados de pagamento do benefício:</strong>
                                <ul>
                                <li>(a) UF onde o Beneficiário recebe os proventos;</li>
                                <li>(b) Tipo de Crédito (Cartão ou Conta-Corrente);</li>
                                <li>(c) Indicação da Instituição Financeira que paga o Benefício;</li>
                                <li>(d) Agência Pagadora;</li>
                                <li>(e) Conta-Corrente onde o Benefício é pago;</li>
                                <li>(f) Margem Consignável Disponível;</li>
                                <li>(g) Margem Consignável Disponível para Cartão;</li>
                                <li>(h) Valor Limite para Cartão;</li>
                                <li>(i) Quantidade de Empréstimos ativos/suspensos.</li>
                                </ul>
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                                <strong>9.1.</strong> Lembre-se que Você é responsável pela qualidade das fotos fornecidas, bem como pela veracidade e atualidade dos dados informados. Quaisquer prejuízos havidos em decorrência do fornecimento de informações inverídicas ou desatualizadas será de sua única e exclusiva responsabilidade.
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                                <strong>9.1.1</strong> Para lhe conferir maior segurança, garantir a veracidade de suas informações e a prevenção contra fraudes, bem como para fins de análise cadastral e de crédito Você autoriza o {termVariables.COMPANY_NAME} a realizar buscas em bureaus públicos e privados, inclusive mediante compartilhamento de dados biométricos, para apuração de eventuais inconsistências em seus dados pessoais, e a solicitar dados pessoais e/ou documentos adicionais, que entender pertinentes. Você compreende que, a depender do resultado de tais consultas, ou, ainda, caso Você se negue a fornecer eventuais dados pessoais adicionais solicitados, o {termVariables.COMPANY_NAME} poderá recusar a contratação de produtos e serviços por Você, sem prejuízo de outras medidas que entender aplicáveis.
                            </DataDisplayDescription>
                            </>

                            <DataDisplayDescription>
                            <p><strong>10. SISTEMA DE INFORMAÇÕES DE CRÉDITO DO BANCO CENTRAL (“SCR”).</strong> Ao aceitar esta Política, em observância à regulamentação expedida pelo Banco Central (“BACEN”), Você autoriza o {termVariables.COMPANY_NAME} a:</p>
                            <p><strong>i)</strong> consultar as informações existentes a seu respeito no SCR;</p>
                            <p><strong>ii)</strong> compartilhar com o BACEN, para integrar o SCR, informações relacionadas ao crédito representado na operação de empréstimo, se contratada.</p>

                            <p><strong>10.1.</strong> Você se declara ciente de que o SCR tem por finalidade fornecer ao BACEN informações para fins de supervisão do risco de crédito a que estão expostas as instituições financeiras, bem como propiciar o intercâmbio de informações entre estas instituições com o objetivo de subsidiar decisões de crédito e de negócios, e a consequente concessão de crédito e/ou oferta de outros produtos e serviços, conforme o caso.</p>

                            <p><strong>10.2.</strong> Caso Você entenda serem necessárias correções, exclusões e/ou manifestações de discordância com relação às informações constantes no SCR, deverá encaminhar requerimento escrito e fundamentado ou, quando for o caso, a respectiva decisão judicial, à Central de Atendimento ao Público do BACEN (ou à instituição responsável pela remessa de tais informações).</p>

                            <p><strong>11. TRATAMENTO DE DADOS PESSOAIS:</strong> O {termVariables.COMPANY_NAME} coletará e armazenará as informações fornecidas por Você, como indicado no item 9 desta Política, em conjunto com algumas informações geradas automaticamente, tais como, as características do dispositivo de acesso, do navegador, protocolo de internet (IP, data, hora, minuto, segundo e porta lógica), log de acesso, informações, aplicativos e telas acessadas, dados de geolocalização, cookies, pixel tags, beacons, local shared objects, device fingerprint (identificação para reconhecer um determinado dispositivo móvel), número de telefone, nome, status e fotografia constantes no perfil utilizado quando da contratação do empréstimo via Plataforma.</p>

                            <p><strong>11.1.</strong> Você compreende que, para a sua identificação e segurança contra fraudes de terceiros mal-intencionados, o {termVariables.COMPANY_NAME} coletará dados biométricos para o que Você declara estar ciente e de acordo, inclusive para o compartilhamento destes dados pelo {termVariables.COMPANY_NAME} com empresas prestadoras de serviços que visem evitar a ocorrência de fraudes.</p>

                            <p><strong>12. FINALIDADES PARA O USO DE DADOS PESSOAIS.</strong> Você declara estar ciente e concordar que os dados fornecidos por Você serão utilizados pelo {termVariables.COMPANY_NAME} para as seguintes finalidades:</p>
                            <p><strong>i)</strong> desenvolver, manter e aperfeiçoar os recursos e/ou produtos fornecidos pelo {termVariables.COMPANY_NAME};</p>
                            <p><strong>ii)</strong> viabilizar a análise de crédito a ser eventualmente ofertado;</p>
                            <p><strong>iii)</strong> contratar e/ou confirmar a contratação de serviços e produtos do {termVariables.COMPANY_NAME};</p>
                            <p><strong>iv)</strong> garantir a prevenção à fraude;</p>
                            <p><strong>v)</strong> assegurar a prevenção à lavagem de dinheiro;</p>
                            <p><strong>vi)</strong> realizar análises relacionadas à segurança, aperfeiçoamento e desenvolvimento de ferramentas antifraude;</p>
                            <p><strong>vii)</strong> permitir o fornecimento de serviços mais personalizados e adequados às suas necessidades, tais como conteúdos e anúncios relevantes;</p>
                            <p><strong>viii)</strong> permitir a comunicação entre Você e o {termVariables.COMPANY_NAME}, inclusive mediante o envio e recebimento de e-mails, contatos telefônicos e/ou envio de mensagens pelas redes sociais;</p>
                            <p><strong>ix)</strong> enviar comunicações a respeito de empréstimos {termVariables.COMPANY_NAME};</p>
                            <p><strong>x)</strong> consultar ofertas disponíveis para Você para outros produtos {termVariables.COMPANY_NAME};</p>
                            <p><strong>xi)</strong> consultar suas informações registradas no FGTS para eventual análise do empréstimo saque aniversário, se for o caso;</p>
                            <p><strong>xii)</strong> consultar suas informações registradas no INSS e sua respectiva margem consignável para eventual análise do empréstimo consignado;</p>
                            <p><strong>xiii)</strong> consultar suas informações registradas em seu órgão pagador, conforme o caso, para oferta de produtos e serviços.</p>

                            <p><strong>12.1.</strong> O {termVariables.COMPANY_NAME} preserva a sua privacidade e não compartilhará seus dados e informações com terceiros salvo mediante seu consentimento, por força da lei ou ordem judicial, e nas hipóteses previstas no item 15 desta Política.</p>

                            <p><strong>13. POLÍTICA DE COOKIES.</strong> Os cookies são pequenos arquivos que podem ou não ser adicionados no seu dispositivo eletrônico, e que permitem armazenar e reconhecer dados de sua navegação. Em sua navegação poderão ser utilizados 04 (quatro) tipos de cookies:</p>
                            <p><strong>a)</strong> Cookies de Autenticação: reconhecem um determinado usuário, possibilitando o acesso e utilização da Plataforma com conteúdo e/ou serviços restritos, proporcionando experiências de navegação mais personalizadas.</p>
                            <p><strong>b)</strong> Cookies de Segurança: ativam recursos de segurança da Plataforma para ajudar no monitoramento e/ou detecção de atividades maliciosas ou vedadas por esta Política, e para proteger as informações dos usuários do acesso por terceiros não autorizados.</p>
                            <p><strong>c)</strong> Cookies de Pesquisa, Análise e Desempenho: ajudam a entender o desempenho da Plataforma, medir a audiência, verificar seus hábitos de navegação, bem como a forma pela qual chegou na página da Plataforma (por exemplo, através de links de outros sites, buscadores ou diretamente pelo endereço).</p>
                            <p><strong>d)</strong> Cookies de Propaganda: apresentam publicidade relevante a seus interesse, dentro e fora da Plataforma ou em sites de parceiros. É usado também bem para saber se os usuários que visualizaram a publicidade visitaram a Plataforma. Podem ser utilizados, ainda, para lembrar eventuais pesquisas anteriores, e apresentar aos usuários anúncios relacionados a seus interesses.</p>
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                            <p><strong>13.1.</strong> Você poderá desabilitar determinados cookies por meio das opções de configuração do seu respectivo navegador (com exceção dos cookies destinados a garantir sua segurança). Contudo, ao fazê-lo, Você está ciente de que é possível que a Plataforma não desempenhe todas as suas funcionalidades.</p>

                            <p><strong>14. MEDIDAS DE SEGURANÇA.</strong> Você compreende que todos os dados pessoais coletados por meio da Plataforma serão armazenados em servidores próprios ou de terceiros, e são considerados confidenciais pelo {termVariables.COMPANY_NAME}, que se compromete a adotar todos os esforços razoáveis de mercado para preservar a segurança de seus dados, entre eles a observância das seguintes diretrizes:</p>
                            <p><strong>a)</strong> Utilização de métodos padrões de mercado para criptografar os dados coletados para garantir sua inviolabilidade;</p>
                            <p><strong>b)</strong> Emprego de softwares de alta tecnologia para proteção contra o acesso não autorizado aos sistemas, sendo estes considerados ambientes controlados e de segurança;</p>
                            <p><strong>c)</strong> Disponibilização de acesso a locais de armazenamento de dados pessoais apenas a pessoas previamente autorizadas, comprometidas ao sigilo de tais dados, inclusive mediante a assinatura de termo de confidencialidade;</p>
                            <p><strong>d)</strong> Aplicação de mecanismos de autenticação de acesso aos registros capazes de individualizar o responsável pelo tratamento e acesso dos dados coletados em decorrência da utilização da Plataforma; e,</p>
                            <p><strong>e)</strong> Manutenção de inventário indicando momento, duração, identidade do funcionário ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto n°8.771/2016.</p>

                            <p><strong>14.1.</strong> Você se compromete a manter o aplicativo para celular sempre atualizado de acordo com a última versão disponível nas lojas oficiais de aplicativos para dispositivos Apple (App Store), Android (Google Play Store). Você também se compromete a possuir smartphone que, por discricionariedade do {termVariables.COMPANY_NAME}, possua os requisitos mínimos que permitam o uso e a atualização do aplicativo sempre que necessário.</p>

                            <p><strong>15. COMPARTILHAMENTO DE DADOS PESSOAIS.</strong> Você autoriza o {termVariables.COMPANY_NAME} a compartilhar os dados coletados por meio da Plataforma, para as finalidades acima indicadas, adotando sempre as medidas de segurança necessárias, com empresas sediadas no Brasil ou no exterior, nos seguintes casos:</p>
                            <p><strong>a)</strong> Se necessário para a prestação dos serviços ofertados pelo {termVariables.COMPANY_NAME};</p>
                            <p><strong>b)</strong> Para a sua segurança, visando a prevenção à fraude nos processos de realização de cadastro ou de transações na Plataforma, com fornecedores especializados em garantir a autenticidade destas transações e/ou instituições financeiras;</p>
                            <p><strong>c)</strong> Para a proteção dos interesses do {termVariables.COMPANY_NAME} em caso de conflito, inclusive em demandas judiciais;</p>
                            <p><strong>d)</strong> Em caso de transações e alterações societárias envolvendo o {termVariables.COMPANY_NAME}, hipótese em que a transferência dos dados será necessária para a continuidade dos serviços ofertados;</p>
                            <p><strong>e)</strong> Com fornecedores e instituições integrantes do conglomerado financeiro do {termVariables.COMPANY_NAME}, para fins publicitários, estatísticos, ofertas de produtos e serviços e para a prestação dos serviços relacionados às funcionalidades da Plataforma;</p>
                            <p><strong>f)</strong> Mediante ordem judicial ou por requerimento de autoridades administrativas que detenham competência legal para a sua requisição; e/ou</p>
                            <p><strong>g)</strong> Com fornecedores responsáveis pela análise da qualidade da prestação dos serviços e produtos ofertados por meio da Plataforma, assim como segmentação dos usuários e clientes para melhor compreender seus comportamentos.</p>
                            <p><strong>h)</strong> Para cumprimento de obrigação legal e/ou regulatória perante autoridades;</p>

                            <p><strong>16. EXCLUSÃO DOS DADOS PESSOAIS.</strong> O {termVariables.COMPANY_NAME} poderá excluir suas informações quando: 
                                <strong>(i)</strong> a finalidade para a qual a informação foi coletada tenha sido alcançada ou quando os dados deixarem de ser necessários para o alcance desta finalidade;
                                <strong>(ii)</strong> quando da revogação do consentimento fornecido por Você para as hipóteses de tratamento de dados pessoais baseadas no seu consentimento ou
                                <strong>(iii)</strong> mediante determinação de autoridade competente para tanto.
                            </p>

                            <p><strong>16.1.</strong> Você compreende que a exclusão de determinados dados poderá impossibilitar a disponibilização de certos produtos, em decorrência de seu aspecto operacional, sem que qualquer indenização lhe seja devida pelo {termVariables.COMPANY_NAME} em razão disto. O {termVariables.COMPANY_NAME} reserva para si o direito de manter armazenados todos os dados necessários ao cumprimento dos contratos que possua com Você e da legislação brasileira vigente, mesmo em caso de requisição de sua exclusão.</p>
                            </DataDisplayDescription>

                            <DataDisplayDescription>
                            <strong>17. SEUS DIREITOS EM RELAÇÃO AO TRATAMENTO DE DADOS PESSOAIS.</strong> Você poderá requerer ao {termVariables.COMPANY_NAME}:
                                <p><strong>a)</strong> A confirmação da existência de tratamento de seus dados pessoais e o acesso aos seus dados coletados pelo {termVariables.COMPANY_NAME};</p>
                                <p><strong>b)</strong> A correção de seus dados, caso estes estejam incompletos, inexatos ou desatualizados;</p>
                                <p><strong>c)</strong> A anonimização, o bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação brasileira aplicável;</p>
                                <p><strong>d)</strong> A portabilidade de seus dados pessoais, para si ou para terceiro;</p>
                                <p><strong>e)</strong> A eliminação dos dados pessoais tratados com o seu consentimento, desde que não haja determinação legal para mantê-los registrados junto ao {termVariables.COMPANY_NAME};</p>
                                <p><strong>f)</strong> A obtenção de informações sobre entidades públicas ou privadas com as quais o {termVariables.COMPANY_NAME} compartilhou seus dados;</p>
                                <p><strong>g)</strong> Informações sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre seu consentimento, bem como de ser informado sobre suas consequências, em caso de negativa; e</p>
                                <p><strong>h)</strong> A revisão de decisões realizadas exclusivamente por meios automatizados.</p>
                            
                            <strong>17.1.</strong> Nesse sentido, para que Você possa exercer seus direitos, basta que entre em contato conosco por meio de um dos nossos Canais de Atendimento indicados no item 21 desta Política, apontando suas dúvidas e/ou requerimentos relacionados a seus dados pessoais, sendo certo que o {termVariables.COMPANY_NAME} empregará os melhores esforços para atendê-lo no menor tempo possível.

                            <p><strong>18. CADASTRO POSITIVO.</strong> De acordo com a Lei Complementar n°166/2019, o {termVariables.COMPANY_NAME} possui como obrigação fornecer dados financeiros e de pagamentos relativos a operações de crédito e obrigações de pagamento adimplidas ou em andamento a gestores de bancos de dados para formação de histórico de crédito. Entretanto, Você pode exercer seus direitos relativos aos seus dados pessoais e informações constantes nos bancos de dados dos gestores dos referidos bancos de dados, por meio dos canais oficiais disponibilizados pelos gestores dos bancos de dados.</p>
                            <p><strong>18.1.</strong> POR MEIO DO ACEITE AOS PRESENTES TERMOS, VOCÊ AUTORIZA OS GESTORES DE BANCO DE DADOS DE QUE TRATA A LEI No 12.414/2011, A DISPONIBILIZAREM AO BANCO {termVariables.COMPANY_NAME}NADO S.A., CNPJ no {termVariables.DOCUMENT_NUMBER} (“{termVariables.COMPANY_NAME}”) O SEU HISTÓRICO DE CRÉDITO, O QUAL ABRANGERÁ OS DADOS FINANCEIROS E DE PAGAMENTOS RELATIVOS ÀS OPERAÇÕES DE CRÉDITO E OBRIGAÇÕES DE PAGAMENTO ADIMPLIDAS EM SEUS RESPECTIVOS VENCIMENTOS E AQUELAS A VENCER, CONSTANTES DE BANCO(S) DE DADOS, COM A FINALIDADE ÚNICA E EXCLUSIVA DE SUBSIDIAR A ANÁLISE E A EVENTUAL CONCESSÃO DE CRÉDITO, A VENDA A PRAZO OU OUTRAS TRANSAÇÕES COMERCIAIS E EMPRESARIAIS QUE IMPLIQUEM RISCO FINANCEIRO. VOCÊ SE DECLARA CIENTE DE QUE ESTA AUTORIZAÇÃO TEM VALIDADE POR TEMPO INDETERMINADO, LIMITADO AO PERÍODO DE DURAÇÃO DO RELACIONAMENTO CONTRATUAL ESTABELECIDO ENTRE VOCÊ E O {termVariables.COMPANY_NAME}. VOCÊ AINDA SE DECLARA CIENTE DE QUE PODERÁ REVOGAR, A QUALQUER TEMPO, ESTA AUTORIZAÇÃO, ASSIM COMO REQUERER A EXCLUSÃO DE SEU CADASTRO ATRAVÉS DOS CANAIS DE COMUNICAÇÃO INDICADOS NO ITEM 21, CASO ENTENDA PERTINENTE.</p>

                            <p><strong>19. DISPOSIÇÕES GERAIS:</strong></p>

                                <p><strong>19.1.</strong> Comunicações: Todas as comunicações encaminhadas por Você ao {termVariables.COMPANY_NAME} serão consideradas plenamente válidas quando realizadas por meio dos Canais de Atendimento ao Cliente, indicados no item 21 deste documento. Por outro lado, todas as comunicações enviadas pelo {termVariables.COMPANY_NAME} serão consideradas válidas quando realizadas por meio de qualquer das informações de contato disponibilizadas por Você. ATENÇÃO! Em qualquer hipótese de recebimento de e-mails encaminhados pelo {termVariables.COMPANY_NAME}, especialmente os que se destinam a solicitar dados ou o pagamento de contas, Você deverá analisar atentamente o emissor de tal mensagem a fim de evitar condutas fraudulentas. O {termVariables.COMPANY_NAME} não solicitará, em qualquer hipótese, senhas ou a confirmação de dados pessoais por e-mail. Caso suspeite da mensagem encaminhada, Você deverá entrar em contato com o {termVariables.COMPANY_NAME} por meio dos Canais de Atendimento.</p>
                                <p><strong>19.2.</strong> Declaração de Nulidade. Qualquer cláusula ou condição desta Política que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições desta Política, as quais permanecerão plenamente válidas, gerando efeitos em sua integral extensão.</p>
                                <p><strong>19.3.</strong> Tolerância. A falha do {termVariables.COMPANY_NAME} em exigir quaisquer direitos ou disposições da presente Política não constituirá renúncia, podendo este exercer regularmente o seu direito, dentro dos prazos legais.</p>


                            <strong>20. LEGISLAÇÃO E FORO.</strong> Esta Política é regida de acordo com a legislação brasileira. Fica eleito o Foro do seu domicílio para dirimir quaisquer dúvidas, questões ou litígios decorrentes da presente Política, renunciando as partes a qualquer outro, por mais privilegiado que seja.

                            <p><strong>21. CANAIS DE ATENDIMENTO.</strong> Central de Relacionamento: {termVariables.CONTACT_INFORMATION_RAW}</p>
                            </DataDisplayDescription>
                        </Box>
                    )}
                </Box>
            </Box>
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />
            }
                <Box
                        style={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            alignItems: "center",
                            textAlign: "center",
                            backgroundColor: "#f9f9f9",
                            padding: "12px 10px",
                            borderTop: "1px solid #ccc"
                        }}
                    >
                        <Button
                            onClick={handleClick}
                            type="primary"
                            clientThemeSettings={clientThemeSettings}
                        >
                            ACEITAR E CONTINUAR
                        </Button>
                        <Footer />
                </Box>
        </Wallpaper>
    )

};