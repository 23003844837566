import styled from 'styled-components';
import * as G from '../../theme/global-styles';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    gap: 16px;
    padding: 0 16px;
`;

export const Description = styled(G.Description)`
    text-align: justify;
`;