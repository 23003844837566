import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
`;

export const Header = styled.div<{$backgroundColor: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 32px;
    justify-content: space-between;
    background-color: ${props => props.$backgroundColor};
`;

export const Title = styled.h1<{$colorMode: string}>`
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    color: ${props => props.$colorMode === 'light mode' ? '#232323' : '#FFFFFF'};
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    gap: 16px;
`;

export const Description = styled.p`
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin: 0;
    color: #232323;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 32px;
`;

export const IconFrame = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
`;