import * as S from './styles';

const PoweredbByQITech = () => {
    return (
        <S.Container href="https://qitech.com.br/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/logo192.png" width={24} height={24} alt="QI Tech"/>
            <S.Description>Powered by</S.Description>
            <S.Description>QI Tech</S.Description>
        </S.Container>
    );
};

export default PoweredbByQITech;