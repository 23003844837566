import { styled } from '../../modules';
import { Typography } from '../../components';
import * as G from '../../theme/global-styles';

export const CollectImageTitle = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '13px';
`;

export const DataDisplayTitle = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '25px';
`;

export const DataDisplayDescription = styled(Typography)`
  margin-top: '2rem';
  margin-bottom: '1rem';
  padding: '0 0.3rem';
  font-size: '13px';
`;

export const DocumentText = styled(Typography)`
  font-weight: bold;
  font-size: 13px;
  text-align: left;
`;

export const DocumentImg = styled.img`
  width: 105px;
  max-height: 105px;
  object-fit: contain;
  margin: auto 0;
`;

export const DocumentIcon = styled.img`
  width: 25px;
  max-height: 25px;
  object-fit: contain;
  margin: auto 0;
  margin-right: 10px;
`;

export const DocumentImgCnh = styled(DocumentImg)`
  max-height: 105px;
  margin: auto 0;
`;

export const PersonalDocumentConfirmationContainer = styled.div`
 margin-top: 16px;
 width: 100%;
 border-radius: 8px;
 box-shadow: #646464ab 0 2px 8px;
 padding: 8px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

export const Description = styled(G.Description)`
    text-align: justify;
    width: 100%;
`;

export const AdditionalDocumentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    gap: 16px;
    padding: 0 16px;
    margin-top: 0px;
`;

export const Subtitle = styled(G.Title)`
    font-size: 16px;
    font-weight: 700;
`;

export const Indent = styled.div`
    padding-left: 32px;
    width: 100%;
`;

export const UnorderedList = styled.ul`
    padding: 0;
    margin: 0;
`;

export const OrderedList = styled.ol`
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    font-size: 14px;
    margin: 0 0 8px 0;
    font-weight: 400;
    color: #232323;

`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 0px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px dashed #737373;
`;

export const DocumentContainer = styled.div<{ $primaryColor: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 16px;
    border: 1px solid ${props => props.$primaryColor};
    background-color: ${props => props.$primaryColor}33;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    &:active {
      background-color: ${props => props.$primaryColor}66;
    }
`;

export const Container = styled(G.Container)`
    padding: 16px 0;
`;