import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border: 1px dashed transparent;
  width: 100%;
  max-width: calc(100vh * 3/4);
  padding: 24px 16px;
  gap: 16px;
  justify-content: space-between;
  overflow-y: auto;
`;

export const Title = styled.p`
    font-size: 20px;
    margin: 0;
    font-weight: 900;
    color: #232323;
    width: 100%;
`;

export const Description = styled.p`
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #232323;
`;