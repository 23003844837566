import React from "react"
import * as S from './styles'
import * as G from '../../theme/global-styles'
import ClientLogo from "../../components/ClientLogo";
import PoweredByQITech from "../../components/PoweredByQITech";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

export const NotFoundPage = (props) => {

    const { clientThemeSettings } = props

    return (
        <G.Container>
            <S.Header>
                {clientThemeSettings?.logo_url ? (
                    <ClientLogo clientThemeSettings={clientThemeSettings} size='small' />
                ) : (
                    <></>
                )}
            </S.Header>
            <S.ExpiredContainer>
                <QuestionMarkCircledIcon color={clientThemeSettings.background_color} width={32} height={32} />
                <G.Title>Página não encontrada</G.Title>
                <S.Description>Verifique se o link está correto e tente novamente.</S.Description>
            </S.ExpiredContainer>
            <S.Footer>
                <PoweredByQITech />
            </S.Footer>
        </G.Container>
    )
}