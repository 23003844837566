import styled from "styled-components";

export const Container = styled.a`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-decoration: none;
`;

export const Description = styled.div`
    margin: 0;
    color: #888;
    font-size: 12px;
    font-weight: light;
`;