import { datadogRum } from "@datadog/browser-rum";

const init = (): void => {
  if (true) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "qisign",
      env: process.env.REACT_APP_ENV,
      version: "1.0.0",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
    });

    datadogRum.startSessionReplayRecording();
  }
};

export function setUserInDatadog(id: string, name: string, email: string) {
  datadogRum.setUser({
    id,
    name,
    email,
  });
}

export function setDatadogError(error: any, context: any) {
  datadogRum.addError(error, context);
}



export function setDatadogPageAction(page: string, context: any) {
  if (process.env.REACT_APP_ENV !== 'local') {
    datadogRum.addAction(page + ' has been loaded', context);
  }
}

export const DataDogService = {
  init,
};
