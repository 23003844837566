import styled from "styled-components";

export const Container = styled.div<{ size: 'small' | 'medium' }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: ${({ size }) => size === 'small' ? '6.25vh' : '12.5vh'};
`;

export const Image = styled.img`
    height: 100%;
    width: auto;
`;