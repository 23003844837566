import * as S from './styles'

export default function AdditionalContent({ content, no_raw_test = true }: { content: any , no_raw_test: boolean}) {
    return (
        <S.Container>
            {no_raw_test && <S.Text>{content.raw_text}</S.Text>}
            {(content.contacts || []).map((contact: any, index: number) => (
                <S.Contact key={index}>
                    <S.ContactName>{contact.name}:</S.ContactName>
                    <S.ContactInformation>
                        {Object.keys(contact.phone || {}).length === 3 &&
                            <S.ContactData
                                href={`tel:+${contact.phone.international_dial_code}${contact.phone.area_code}${contact.phone.number}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                +{contact.phone.international_dial_code} ({contact.phone.area_code}) {contact.phone.number}
                            </S.ContactData>
                        }
                        {contact.email &&
                            <S.ContactData
                                href={`mailto:${contact.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {contact.email}
                            </S.ContactData>
                        }
                        {contact.url &&
                             <S.ContactData
                             href={contact.url}
                             target="_blank"
                             rel="noopener noreferrer"
                         >
                             {contact.url}
                         </S.ContactData>
                        }
                    </S.ContactInformation>
                </S.Contact>
            ))}
        </S.Container>
    )
}