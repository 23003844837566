import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
`;

export const Base = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #232323;

    font-weight: light;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const ClientLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 15vh;
`;

export const Image = styled.img`
    height: 100%;
    width: auto;
`;

export const Placeholder = styled.div`
    height: 66px;
    width: 100%;
`;