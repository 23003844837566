import React, { useState, useEffect, useCallback, useMemo } from 'react'
import axios from 'axios'
import { LoadingPage } from '../LoadingPage'
import { formatPhone } from '../../utils/utils'
import { OTPInput } from './OTPInput'
import * as S from './styles'
import * as G from '../../theme/global-styles'
import { ThemedButton } from '../../components/ThemedButton'
import ClientLogo from '../../components/ClientLogo'
import PoweredbByQITech from '../../components/PoweredByQITech'
import { Wallpaper } from 'src/components/Wallpaper'
import Logo from 'src/components/Logo'
import { Button } from '../../components';
import { Footer } from '../../components/Footer';
import FormField from '../../components/FormField'

export const OTPPage = (props) => {

    const { setSignerStep, clientThemeSettings, setSignData, signData, onSendOTPCode, appTheme } = props

    const submissionMethod = ((signData || {}).signer_data || {}).authentication_submission_method
    const allow_update_authentication_data = ((signData || {}) || {}).allow_update_authentication_data
    const email = ((signData || {}).signer_data?.email || null)
    const phone = ((signData || {}).signer_data?.phone ? signData.signer_data?.phone?.international_dial_code + signData.signer_data?.phone?.area_code + signData.signer_data?.phone?.number : null)

    const emailRegex = useMemo(() => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]((?!\.\.)\.([a-zA-Z]{2,}))+$/, []);
    const checkEmail = useCallback((email) => {
        return emailRegex.test(email)
    }, [emailRegex])

    const formatPhoneNumber = (value) => {
        if (value) {
            const digits = value.replace(/\D/g, '');
            if (digits.length <= 10) {
                // Format as (XX) XXXX-XXXX (landline)
                return digits
                    .replace(/(\d{2})(\d)/, '($1) $2')
                    .replace(/(\d{4})(\d)/, '$1-$2')
                    .slice(0, 14);
            } else {
                // Format as (XX) XXXXX-XXXX (mobile)
                return digits
                    .replace(/(\d{2})(\d)/, '($1) $2')
                    .replace(/(\d{5})(\d)/, '$1-$2')
                    .slice(0, 15);
            }
        }
        return '';
    }
    
    const [previousPhone, setPreviousPhone] = useState(phone)
    const [previousEmail, setPreviousEmail] = useState(email)

    const [phoneLabel, setPhoneLabel] = useState(String(phone))
    const [emailLabel, setEmailLabel] = useState(String(email))

    const [phoneData, setPhoneData] = useState(String(
        signData?.signer_data?.phone ? formatPhoneNumber(signData.signer_data.phone.area_code + signData.signer_data.phone.number).trim() : ''
    ))
    const [emailData, setEmailData] = useState(String(email || ''))

    const [formDataIsOk, setFormDataIsOk] = useState(false)
    const [formDataChanged, setFormDataChanged] = useState(false)

    useEffect(() => {
        let dataIsOk = true
    
        if(email){
            if(!checkEmail(emailData || ''))
                dataIsOk = false
        }

        if(phone){
            if((phoneData || '').length < 14 || (phoneData || '').length > 15)
                dataIsOk = false
        }

        setFormDataIsOk(dataIsOk)

    }, [phoneData, emailData, phone, email, checkEmail])

    useEffect(() => {
        let dataChanged = false
    
        if(email){
            if(previousEmail !== emailData)
                dataChanged = true
        }

        if(phone){
            const formattedPhone = (phoneData || '').replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
            let area_code = formattedPhone.slice(0, 2)
            let number = formattedPhone.slice(2)              
            let newPhoneValue = area_code + number
            let oldPhoneValue = (previousPhone || '').slice(2)

            if(oldPhoneValue !== newPhoneValue)
                dataChanged = true
        }

        setFormDataChanged(dataChanged)

    }, [phoneData, emailData, phone, email, previousPhone, previousEmail])

    const [loading, setLoading] = useState(false)
    const initialError = {
        "has_error": false,
        "status": 0
    }
    const [error, setError] = useState(initialError)

    const [otpInput, setOTPInput] = useState({ "otp1": "", "otp2": "", "otp3": "", "otp4": "", "otp5": "", "otp6": "" })

    const onUpdateSignerAuthenticationData = () => {
        let areaCode = ''
        let number = ''

        let data = {
        }
        if (phone) {
            const formattedPhone = (phoneData || '').replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
            areaCode = formattedPhone.slice(0, 2)
            number = formattedPhone.slice(2)

            data['phone'] = {
                international_dial_code: '55',
                area_code: areaCode,
                number: number,
            }
        }

        if (email)
            data['email'] = emailData

        const config = {
            headers:
            {
                'Authorization': (signData || {}).signer_token || ''
            }
        }

        setLoading(true)
        axios
            .patch('/signer/authentication/data', data, config)
            .then((response) => {
                if(response?.data?.phone){
                    let fullPhoneData = (response.data.phone.international_dial_code || '') + (response.data.phone.area_code || '') + (response.data.phone.number || '')
                    setPreviousPhone(fullPhoneData)
                    setPhoneData(String(formatPhoneNumber((response.data.phone.area_code || '') + (response.data.phone.number || '')).trim() || ''))
                    setPhoneLabel(fullPhoneData)
                }

                if(response?.data?.email){
                    setPreviousEmail(emailData)
                    setEmailData(String(response.data.email || ''))
                    setEmailLabel(String(response.data.email || ''))
                }

                handleSendOTPCode()
                resetSignerDataUpdateForm()
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setLoading(false)
            })
    }

    const onConfirmOTPCode = () => {
        let otpConcatenated = ""
        for (let i = 1; i < 7; i++) {
            otpConcatenated += otpInput[`otp${i}`] || ''
        }

        const data = {
            "authentication_code": otpConcatenated,
        }
        const config = {
            headers:
            {
                'Authorization': (signData || {}).signer_token || ''
            }
        }
        setLoading(true)
        axios
            .patch('/signer/authentication', data, config)
            .then((response) => {
                setSignData({ ...(signData || {}), ...(response?.data || {}) })
                if (response?.data?.next_step) {
                    setSignerStep(response.data.next_step)
                }
            }).catch((error) => {
                if ([401, 403].includes(((error || {}).response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    const [isBlocked, setIsBlocked] = useState(true)
    const [remainingTime, setRemainingTime] = useState(60)

    const handleSendOTPCode = useCallback(async () => {
        if (isBlocked) return
        setIsBlocked(true)
        setRemainingTime(90)
        onSendOTPCode((signData || {}).signer_token || '')

    }, [isBlocked, onSendOTPCode, signData])

    useEffect(() => {
        let interval
        if (isBlocked) {
            interval = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1)
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [isBlocked, handleSendOTPCode])

    useEffect(() => {
        if (remainingTime === 0) {
            setIsBlocked(false)
        }
    }, [remainingTime])

    const [showSignerDataUpdateForm, setShowSignerDataUpdateForm] = useState(false)
    const resetSignerDataUpdateForm = () => {
        setShowSignerDataUpdateForm(false)
        setFormDataChanged(false)
    }

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    if (appTheme === 'release') {
        return (
            <G.Container>
                <ClientLogo clientThemeSettings={clientThemeSettings || {}} size="small" />
                <S.OTPContainer>
                    <G.Title>Insira o código de autenticação</G.Title>
                    <S.Description>
                        O código de autenticação foi enviado para <b>{submissionMethod === 'email' ? (emailLabel || '') : formatPhone(phoneLabel || '')}</b>.
                        {
                            allow_update_authentication_data &&
                            <S.UpdateSignerDataButton onClick={() => {
                                if(showSignerDataUpdateForm){
                                    setShowSignerDataUpdateForm(false)
                                } else{
                                    setShowSignerDataUpdateForm(true)
                                }
                            }}>
                                {showSignerDataUpdateForm ? 'Cancelar' : 'Editar'}
                            </S.UpdateSignerDataButton>
                        }    
                    </S.Description>
                    {
                        showSignerDataUpdateForm &&
                        <>
                            <S.Divider />
                            <S.FormContainer>
                                <G.Title>Atualize seus dados</G.Title>
                                <S.Text>
                                    Preencha os campos abaixo e depois clique em "atualizar":
                                </S.Text>
                                <S.InputContainer>
                                {
                                    phone && 
                                    <FormField
                                        hasError={(phoneData.length < 14 || phoneData.length > 15)}
                                        labelValue="Telefone"
                                        fieldValue={formatPhoneNumber(phoneData)}
                                        onChange={(e) => {
                                            setPhoneData(formatPhoneNumber(e.target.value).trim())                                         
                                        }}
                                        errorMessage=""
                                        type="numeric"
                                    />
                                }
                                {
                                    email &&
                                    <FormField
                                    hasError={!emailRegex.test(emailData)}
                                    labelValue="Email"
                                    fieldValue={emailData}
                                    onChange={(e) => {
                                        setEmailData(e.target.value.toLowerCase().trim())
                                    }}
                                    errorMessage=""
                                    type="email"
                                />
                                }
                                </S.InputContainer>
                            </S.FormContainer> 
                            <ThemedButton
                                onClick={() => onUpdateSignerAuthenticationData()}
                                clientThemeSettings={clientThemeSettings}
                                $disabled={!formDataIsOk || !formDataChanged || loading}
                                $loading={loading}
                            >
                                Atualizar
                            </ThemedButton>
                            <S.Divider />
                        </>
                    }
                    <S.CentralizedContainer>
                        <S.Description>
                            Confirme o código para finalizar:
                        </S.Description>
                        <OTPInput
                            error={error || initialError}
                            otpInput={otpInput || {}}
                            setOTPInput={setOTPInput}
                        />
                        {error.has_error &&
                            <S.Error>
                                O código não confere com o enviado.
                            </S.Error>
                        }
                        <S.ResendCodeContainer>
                            <S.Description>Deseja reenviar o código?</S.Description>
                            <S.ResendCodeButton onClick={handleSendOTPCode}>
                                {isBlocked ? 'Bloqueado por ' : 'Reenviar '}
                                {isBlocked && <b>{`${remainingTime}s`}</b>}
                            </S.ResendCodeButton>
                        </S.ResendCodeContainer>
                    </S.CentralizedContainer>
                </S.OTPContainer>
                <ThemedButton
                    onClick={onConfirmOTPCode}
                    clientThemeSettings={clientThemeSettings || {}}
                    disabled={Object.values(otpInput || {}).filter((value) => (value === "")).length > 0 || loading}
                    loading={loading}
                >
                    Confirmar
                </ThemedButton>
                <S.Footer>
                    <PoweredbByQITech />
                </S.Footer>
            </G.Container>
        );
    }

    return (
        <Wallpaper
            backgroundColor={(clientThemeSettings || {}).background_color}
        >
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={(clientThemeSettings || {}).logo_url} />

                <div className="no-border-container-fullwidth description-level4 gray" style={{ textAlign: "center" }}>
                    <p style={{ display: "inline", margin: "5px", padding: "0" }}>
                        <span className="bold">Código de autenticação </span>
                        <span>enviado para </span>
                        <span className="bold">{submissionMethod === 'email' ? (email || '') : formatPhone(phone || '')}</span>
                    </p>
                    <p style={{ margin: "5px", padding: "0" }}>
                        Confirme o código para finalizar:
                    </p>
                </div>
                <div className="no-border-container-fullwidth">
                    <OTPInput
                        error={error || initialError}
                        otpInput={otpInput || {}}
                        setOTPInput={setOTPInput}
                    />
                    {error.has_error &&
                        <p className={"form-error"} style={{ margin: "10px auto" }}>
                            O código não confere com o enviado.
                        </p>}
                    <div className="otp__notification-container">
                        <div>Deseja reenviar o código?</div>
                        <div
                            onClick={handleSendOTPCode}
                            className="otp__notification-link link"
                            disabled={isBlocked}
                        >
                            <span>{isBlocked ? 'Bloqueado por ' : 'Reenviar '}</span>
                            {isBlocked && <span className="bold">{`${remainingTime}s`}</span>}
                        </div>
                    </div>
                </div>
                <Button
                    onClick={() => (Object.values(otpInput || {}).filter((value) => (value === "")).length === 0) ? onConfirmOTPCode() : null}
                    clientThemeSettings={clientThemeSettings || {}}
                >
                    CONFIRMAR
                </Button>
                <Footer />
            </div>
        </Wallpaper>
    )
}