import styled from 'styled-components';
import * as G from '../../theme/global-styles';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

export const Description = styled(G.Description)`
    text-align: justify;
    width: 100%;
`;

export const TermContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
    gap: 16px;
    margin-top: 0px;
`;

export const Subtitle = styled(G.Title)`
    font-size: 16px;
    font-weight: 700;
`;

export const Indent = styled.div`
    padding-left: 32px;
    width: 100%;
`;

export const UnorderedList = styled.ul`
    padding: 0;
    margin: 0;
`;

export const OrderedList = styled.ol`
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    font-size: 14px;
    margin: 0 0 8px 0;
    font-weight: 400;
    color: #232323;

`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 0px 16px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px dashed #737373;
`;
