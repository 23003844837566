export const isNaturalDocumentNumber = (value) => {
    if (value) {
        if (justNumbers(value).length < 11) return false
        else return true
    }
}

export const cpfMask = value => {
    if (value) {
        return value.slice(0, 14)
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
}

export const isDate = (value) => {

    if (value) {
        let day = Number(justNumbers(value).substring(0, 2))
        let month = Number(justNumbers(value).substring(2, 4))
        let year = Number(justNumbers(value).substring(4, 8))
        if (value.length < 9) return false
        else if (day < 1 || day > 31) return false
        else if (month < 1 || month > 12) return false
        else if (year < 1900) return false
        else return true
    }

}

export const isName = (value) => {
    if (value === "") return false
    else return true
}

export const nameMask = (value) => {
    return value;
}

export const formatName = (value) => {
    return value.trim();     
}

export const dateMask = value => {
    if (value) {
        let v = value.replace(/\D/g, '').slice(0, 8)
        if (v.length >= 5) {
            return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
        }
        else if (v.length >= 3) {
            return `${v.slice(0, 2)}/${v.slice(2)}`
        }
        return v
    }
}

export const justNumbers = (value) => {
    if (value) {
        return value.replace(/\D/g, '')
    }
}

export const formatToYYYYMMDD = (dateDDMMYYYY) => {
    const d = dateDDMMYYYY.replace(/\D/g, '').slice(0, 8);

    const day = d.slice(0, 2);
    const month = d.slice(2, 4);
    const year = d.slice(4);

    return `${year}-${month}-${day}`;
}

export const defaultThemeSettings = {
    "background_color": "#6c84ac"
}

export const formatPhone = (unformattedPhone) => {
    const countryCode = (unformattedPhone || '').slice(0, 2)
    const areaCode = (unformattedPhone || '').slice(2, 4)
    const phoneCodePart1 = (unformattedPhone || '').slice(4, 9)
    const phoneCodePart2 = (unformattedPhone || '').slice(9, 13)
    return `+${countryCode} (${areaCode}) ${phoneCodePart1}-${phoneCodePart2}`
}

export const isMobile = () => {
    return /Android|iPhone/i.test(navigator.userAgent)
}