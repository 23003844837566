import React, { useEffect, useState, useCallback } from "react"
import LoadingFrame from '../../components/LoadingFrame'
import axios from "axios"
import { ErrorPage } from "../ErrorPage"
import {
    HeaderText,
    CheckBoxChecked,
    CheckBoxEmpty,
    Wrapper,
    PdfContainer,
    ButtonExternalContainer,
    ButtonInternalContainer,
    ButtonDescription,
    ButtonRefresh,
    SkeletonContainer,
    Header,
    HeaderLogo,
    HeaderLeftSide,
} from './styles'
import * as S from './styles'
import * as G from '../../theme/global-styles'
import PoweredByQITech from "../../components/PoweredByQITech";
import ClientLogo from "../../components/ClientLogo";
import {
    isMobile
} from '../../utils/utils'
import { ThemedButton } from "../../components/ThemedButton"
import { IconButton } from "../../components/IconButton"
import { ReloadIcon, DownloadIcon } from "@radix-ui/react-icons"
import { Wallpaper } from '../../components/Wallpaper';
import { Refresh, Download } from "@mui/icons-material"
import { Button } from '../../components';
export const DocumentsInbox = (props) => {
    const { clientThemeSettings, signData, setSignerStep, setSignData, appTheme } = props
    const [loading, setLoading] = useState(false)
    const initialError = {
        "has_error": false,
        "status": 0
    }
    const [error, setError] = useState(initialError)
    const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0)
    const [pdfSrc, setPdfSrc] = useState('')
    const [hideIframe, setHideIframe] = useState(true)
    const [disablePrimaryButton, setDisabledPrimaryButton] = useState(true)
    const [wasRead, setWasRead] = useState(signData.documents.map(() => ({
        wasRead: false,
    })))

    const reloadPage = () => {
        setDisabledPrimaryButton(true)
        setHideIframe(true)

        setTimeout(() => setHideIframe(false), 500)
    }

    const downloadPDF = (pdfUrl, documentName) => {
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = documentName + '.pdf'
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const onSubmit = async () => {
        const data = {
            "event_date": new Date().toISOString(),
            "step": "document_display"
        }
        const config = {
            headers:
            {
                'Authorization': signData.signer_token
            }
        }

        await axios.patch('/signer', data, config)
            .then(response => {
                setSignerStep(response.data.next_step)
                setSignData({ ...signData, ...response.data})
            })
            .catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const setPdfLink = useCallback((currentDocumentIndex) => {
        if (isMobile()) {
            setPdfSrc(`https://docs.google.com/viewer?url=${encodeURIComponent(signData.documents[currentDocumentIndex].url)}&embedded=true`)
            return
        }

        setPdfSrc(signData.documents[currentDocumentIndex].url)
    }, [signData.documents])

    const setReadDocuments = async (documentKey) => {
        const updatedWasRead = wasRead.map((documentReadState, index) =>
            index === documentKey ? { wasRead: true } : documentReadState
        )
        setWasRead(updatedWasRead)

        if (documentKey + 1 === signData.documents.length) {
            await onSubmit()
            return
        }

        setPdfLink(documentKey + 1)
        setCurrentDocumentIndex(documentKey + 1)
        reloadPage()
    }

    const enableSignButton = () => setDisabledPrimaryButton(false)

    useEffect(() => {
        setPdfLink(currentDocumentIndex)
        setDisabledPrimaryButton(true)
        setHideIframe(true)

        setTimeout(() => setHideIframe(false), 500)
    }, [currentDocumentIndex, setPdfLink])


    if (appTheme === 'release') { 
        return (
            <S.Container>
                <S.Header>
                    <S.HeaderSide>
                        <ClientLogo clientThemeSettings={clientThemeSettings} size='small'/>
                    </S.HeaderSide>
                    <S.HeaderSide>
                        <HeaderText>
                            Documentos:
                        </HeaderText>
                        {wasRead.map((documentReadState, index) => (
                            !documentReadState.wasRead ?
                                <CheckBoxEmpty key={index} $color={'#d7d7d7'} /> :
                                <CheckBoxChecked key={index} $color={clientThemeSettings.background_color} />
                        ))}
                    </S.HeaderSide>
                </S.Header>
                <S.DocumentContainer>
                    {!hideIframe ? (
                        <iframe
                            key={currentDocumentIndex}
                            title={signData.documents[currentDocumentIndex].name}
                            src={pdfSrc}
                            onLoad={() => {
                                enableSignButton()
                            }}
                            style={{ width: '100%', height: '100%', border: 'none' }}
                        />
                    ) : (
                        <S.SkeletonContainer>
                            <LoadingFrame />
                        </S.SkeletonContainer>
                    )}
                </S.DocumentContainer>
                <S.Footer>
                    <G.Description>
                        {signData.documents[currentDocumentIndex].name}
                    </G.Description>
                    {   
                        !signData.credit_operation && 
                        <S.Description>
                            Clique em <strong>"Assinar"</strong> para aceitar esse contrato e para seguir com a contratação do empréstimo.
                        </S.Description>
                    }
                    <S.ControlBar>
                        <S.IconButtonWrapper>
                            <IconButton
                                onClick={reloadPage}
                                clientThemeSettings={clientThemeSettings}
                                type={'primaryGhost'}
                                loading={loading}
                            >
                                <ReloadIcon color={clientThemeSettings.background_color} />
                            </IconButton>
                            <IconButton
                                onClick={() => (downloadPDF(signData.documents[currentDocumentIndex].url, signData.documents[currentDocumentIndex].name))}
                                clientThemeSettings={clientThemeSettings}
                                type={'primaryGhost'}
                            >
                                <DownloadIcon color={clientThemeSettings.background_color} />
                            </IconButton>
                        </S.IconButtonWrapper>
                        <S.SignButtonWrapper>
                            <ThemedButton
                                onClick={() => {
                                    setReadDocuments(currentDocumentIndex)
                                }}
                                clientThemeSettings={clientThemeSettings}
                                loading={loading}
                                disabled={disablePrimaryButton}
                            >
                                Assinar
                            </ThemedButton>
                        </S.SignButtonWrapper>
                    </S.ControlBar>
                    <PoweredByQITech />
                </S.Footer>
                {error.has_error &&
                    <ErrorPage
                        error={error}
                        redirectToLogin={() => setSignerStep("login")}
                        resetError={() => setError(initialError)}
                        clientThemeSettings={clientThemeSettings}
                    />
                }
            </S.Container>
        );
    }

    return (
        <Wrapper>
            <PdfContainer>
                <Header>
                    <HeaderLogo
                        src={clientThemeSettings.logo_url}
                        alt="Logo Cliente"
                    />
                    <HeaderLeftSide>
                        <HeaderText>
                            Documentos:
                        </HeaderText>
                        {wasRead.map((documentReadState, index) => (
                            !documentReadState.wasRead ?
                                <CheckBoxEmpty key={index} $color={'#d7d7d7'} /> :
                                <CheckBoxChecked key={index} $color={clientThemeSettings.background_color} />
                        ))}
                    </HeaderLeftSide>
                </Header>
                {!hideIframe ? (
                    <iframe
                        key={currentDocumentIndex}
                        title={signData.documents[currentDocumentIndex].name}
                        src={pdfSrc}
                        onLoad={() => {
                            enableSignButton()
                        }}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                ) : (
                    <SkeletonContainer>
                        <LoadingFrame />
                    </SkeletonContainer>
                )}
                <ButtonExternalContainer>
                    <ButtonDescription>
                        {signData.documents[currentDocumentIndex].name}
                    </ButtonDescription>
                    <ButtonInternalContainer>
                        <ButtonRefresh
                            onClick={reloadPage}
                            clientThemeSettings={clientThemeSettings}
                            type={'primaryGhost'}
                            disabled={loading}
                        >
                            <Refresh />
                        </ButtonRefresh>

                        <ButtonRefresh
                            onClick={() => (downloadPDF(signData.documents[currentDocumentIndex].url, signData.documents[currentDocumentIndex].name))}
                            clientThemeSettings={clientThemeSettings}
                            type={'primaryGhost'}
                        >
                            <Download />
                        </ButtonRefresh>

                        <Button
                            onClick={() => {
                                setReadDocuments(currentDocumentIndex)
                            }}
                            clientThemeSettings={clientThemeSettings}
                            loading={loading}
                            disabled={disablePrimaryButton}
                        >
                            ASSINAR
                        </Button>
                    </ButtonInternalContainer>
                </ButtonExternalContainer>
            </PdfContainer>
            {error.has_error && (
                <Wallpaper
                    backgroundColor={clientThemeSettings["background_color"]}
                >
                    {error.has_error &&
                        <ErrorPage
                            error={error}
                            redirectToLogin={() => setSignerStep("login")}
                            resetError={() => setError(initialError)}
                            clientThemeSettings={clientThemeSettings}
                        />
                    }
                </Wallpaper>
            )}
        </Wrapper>
        
    )

}