import styled from 'styled-components'
import * as G from '../../theme/global-styles'

export const Button = styled.div<{ clientThemeSettings: any, disabled?: boolean }>`
    padding: 4px 8px;
    border-radius: 8px;
    color: ${props => props.clientThemeSettings.text_color};
    background-color: ${props => props.disabled ? '#ccc' : props.clientThemeSettings.button_color};
    font-size: 13px;
    width: 100%;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Card = styled.div`
    margin: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #646464ab;
    box-shadow: #646464ab 0 2px 8px;
    background-color: rgb(245, 245, 242);
    gap: 16px;
    max-height: 90vh;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
`;

export const Text = styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #232323;
    text-align: justify;
    margin: 0;
`;

export const Title = styled(Text)`
    font-size: 22px;
    font-weight: 600;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #646464ab;
    border-radius: 4px;
`;

export const Selector = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 24px;
    border-radius: 8px;
    border: 1px solid #646464ab;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 8px;
`;

export const SelectorOption = styled.div<{ selected?: boolean, clientTheme: any, fontColorMode: string }>`
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
    color: ${props => props.selected ? (props.fontColorMode === 'light mode' ? '#232323' : '#ffffff') : '#232323'};
    width: 100%;
    text-align: center;
    background-color: ${props => props.selected ? props.clientTheme?.background_color : 'transparent'};
`;

export const LegacySelectorOption = styled.div<{ selected?: boolean, selectedColor: string, fontColorMode: string }>`
font-size: 15px;
font-weight: 400;
line-height: 24px;
height: 24px;
color: ${props => props.selected ? (props.fontColorMode === 'light mode' ? '#232323' : '#ffffff') : '#232323'};
width: 100%;
text-align: center;
background-color: ${props => props.selected ? props.selectedColor : 'transparent'};
`;

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 16px;
`;

export const Radio = styled.input`
`;

export const RadioRoot = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
`;

export const RadioLabel = styled.label`
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #232323;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-bottom: 16px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-y: auto;
`;

export const Description = styled(G.Description)`
    text-align: justify;
    width: 100%;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 0 16px;
    margin-top: 0px;
    flex: 1;
`;

export const Subtitle = styled(G.Title)`
    font-size: 16px;
    font-weight: 700;
`;

export const Indent = styled.div`
    padding-left: 32px;
    width: 100%;
`;

export const UnorderedList = styled.ul`
    padding: 0;
    margin: 0;
`;

export const OrderedList = styled.ol`
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    font-size: 14px;
    margin: 0 0 8px 0;
    font-weight: 400;
    color: #232323;

`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 0px 16px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px dashed #737373;
`;

export const Callout = styled.div<{ clientTheme: any }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    border-radius: 16px;
    background-color: ${props => props.clientTheme?.background_color}33;
    padding: 16px;
    margin-top: 16px;
`;

export const CalloutText = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #232323;
    margin: 0;
`;

export const CalloutTitle = styled.p`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ProgressIndicator = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

export const ProgressStep = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 80px;

`;

export const ProgressStepNumber = styled.div<{ active: boolean, clientTheme: any, fontColorMode: string }>`
    font-size: 20px;
    font-weight: 900;
    color: ${props => props.active ? (props.fontColorMode === 'light mode' ? '#232323' : '#ffffff') : props.clientTheme.button_color};
    background-color: ${props => props.active ? props.clientTheme.button_color : 'transparent'};
    border: 1px solid ${props => props.clientTheme.button_color};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;  

export const ProgressStepLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #232323;
`;

export const StyledSelect = styled.select`
    background-color: #19247e05;
    border: 1px solid rgb(204, 204, 204);
    height: 40px;
    border-radius: 4px;
    padding-left: 6.4px;
    padding-right: 32px;
    font-size: 14px;
    font-family: Arial;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    cursor: pointer;

    option {
        padding: 12px;
        font-size: 14px;
        background-color: #f8f8f8;
        color: #333;
    }

    option:checked {
        background-color: #e6e6e6;
        font-weight: 500;
    }
`;