import * as S from "./styles"
import Theme from "../../interfaces/Theme"

export default function ClientLogo({ clientThemeSettings, size }: { clientThemeSettings: Theme, size?: 'small' | 'medium' }) {
    return (
        <S.Container size={size}>
            {!!clientThemeSettings &&
                <S.Image src={clientThemeSettings.logo_url} alt="client-logo" />
            }
        </S.Container>
    )
}