import darkOrLightMode from '../../utils/helpers/colorMode';
import QISignTheme from '../../interfaces/Theme';
import * as S from './styles';

export const IconButton = ({ children, clientThemeSettings, ...props }: { children: React.ReactNode, clientThemeSettings: QISignTheme, [key: string]: any }) => {
    
    return (
        <S.MainButton
            $backgroundColor={clientThemeSettings.background_color}
            $textColor={darkOrLightMode(clientThemeSettings.background_color) === 'light mode' ? '#232323' : '#FFFFFF'}
            $loading={props.$loading}
            $disabled={props.$disabled}
            onClick={props.$disabled || props.$onClick ? () => {} : props.$onClick} 
            {...props}
        >
            {children}
        </S.MainButton>
    );
};