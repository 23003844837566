import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 24px 12px;
    gap: 16px;
`;

export const Text = styled.p`
    width: 100%;
    text-align: justify;
    margin: 0;
    color: #333;
    white-space: pre-wrap;
`;

export const Contact = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 4px;
    margin-top: 4px;
`;

export const ContactName = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 0;
`;

export const ContactInformation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 8px;
    align-items: flex-start;
`;

export const ContactData = styled.a`
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    color: #007bff;
`;

