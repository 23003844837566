import React from "react"
import "../assets/styles/index.css"

const FormField = (props) => {

  const { hasError, labelValue, onChange, onBlur, fieldValue, errorMessage, onKeyDown } = props

  const inputClasses = hasError ?
    ["description-level5", "form-control", "invalid"].join(" ")
    : ["description-level5", "form-control"].join(" ")

  let readOnly = false
  if (props.readOnly) {
    readOnly = props.readOnly
  }

  return (
    <div className={inputClasses} style={{ fontWeight: "400", color: "rgba(51, 65, 80, 0.7)" }}>
      <div style={{ color: "#7f8fa4" }}>{labelValue}</div>
      <input
        type={props.type ? props.type : "text"}
        readOnly={readOnly}
        onChange={readOnly ? null : onChange}
        onBlur={onBlur}
        value={fieldValue}
        onKeyDown={onKeyDown}
        pattern={props.type === "numeric" ? "[0-9]*" : null}
        inputMode={props.type === "numeric" ? "numeric" : 'text'}
      />
      {hasError ? (
        <p className={"form-error"}>
          {errorMessage}
        </p>
      ) : (
        <div style={{ minHeight: "3px" }}></div>
      )}
    </div>
  )
}

export default FormField
