import { Dialog } from "@mui/material"
import darkOrLightMode from '../../../utils/helpers/colorMode';
import * as S from './styles';
import QISignTheme from "../../../interfaces/Theme";
import { ThemedButton } from "../../../components/ThemedButton";
import { Cross2Icon } from "@radix-ui/react-icons";
export const LocationDialog = ({
    open,
    onClose,
    onSubmit,
    geolocationFailureRequestCount,
    clientThemeSettings
} : {
    open: boolean,
    onClose: () => void,
    onSubmit: () => void,
    geolocationFailureRequestCount: number,
    clientThemeSettings: QISignTheme
}) => {

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
            keepMounted={false}
        >
            <S.Container>
                <S.Header $backgroundColor={clientThemeSettings.background_color}>
                    <S.Title $colorMode={darkOrLightMode(clientThemeSettings.background_color)}>
                        Atenção
                    </S.Title>
                    <S.IconFrame onClick={onClose}>
                        <Cross2Icon width={24} height={24} color={darkOrLightMode(clientThemeSettings.background_color) === 'light mode' ? '#232323' : '#fff'}/>
                    </S.IconFrame>
                </S.Header>
                <S.DescriptionContainer>
                    <S.Description>
                        Você deve aceitar compartilhar sua geolocalização para continuar o processo.
                    </S.Description>
                    {geolocationFailureRequestCount > 1 ?
                        <S.Description>Caso tenha negado, vá para as configurações de navegador e aceite compartilhá-las.</S.Description>
                        :
                        <S.Description>Identificamos que o acesso à geolocalização está negado. Entre nas configurações do navegador ou do celular para autorizar a captura da geolocalização.</S.Description>
                    }
                </S.DescriptionContainer>
                <S.ButtonContainer>
                    <ThemedButton
                        clientThemeSettings={clientThemeSettings}
                        loading={false}
                        onClick={onSubmit}
                    >
                        Continuar
                    </ThemedButton>
                </S.ButtonContainer>
            </S.Container>
        </Dialog>
    );
}