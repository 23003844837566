import React from "react"
import styled from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { MainContainer } from '../MainContainer'
import { WatcherMainContainer } from '../WatcherMainContainer'
import { SignerBatchMainContainer } from "../SignerBatchMainContainer"
import { RedirectContainer } from '../RedirectContainer'
import { NotFoundPage } from '../NotFoundPage'
import { defaultThemeSettings } from '../../utils/utils'

export const MainRouter = (props) => {

    const router = createBrowserRouter([
        {
            path: "/s/:signerHash",
            element: <MainContainer />
        },
        {
            path: "/r/:signerHash",
            element: <RedirectContainer />
        },
        {
            path: "/sb/:signerBatchHash",
            element: <SignerBatchMainContainer/>
        },
        {
            path: "/w/:watcherHash",
            element: <WatcherMainContainer/>
        },
        {
            path: "*",
            element: <NotFoundPage clientThemeSettings={defaultThemeSettings} />
        }
    ])

    const Main = styled.main`
        height: 100vh;
        height: 100dvh;
        width: 100vw;
        background-color: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
    `

    return (
        <div>
            <CssBaseline />
            <Main>
                <RouterProvider router={router} />
            </Main>
        </div>
    )
}
